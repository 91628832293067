import React, { Component } from 'react'
import { Row, Col, Select, Input, Form, Table, notification, Spin, Statistic, Button, InputNumber, Checkbox, Drawer, Space, Typography } from 'antd'
import { FilterOutlined, LoadingOutlined, PrinterOutlined, LogoutOutlined, ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons'
import Http from '../../utils/http'
import Cookies from 'universal-cookie'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { pages as PAGE, constants, timezone } from '../../constants/constants'
import { handlingTimes, getLeastAWB, cacheRetrieve } from '../../utils/common'
import './index.css'
import { pageHandler, getCookie, COOKIE, logout } from '../../utils/cookie'

const country = ['USA', 'U.S. VIRGIN ISLANDS', 'PUERTO RICO']
const { Option } = Select
const { Text } = Typography
const { Countdown } = Statistic
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const CHARNUM3COMMA = '^[0-9a-zA-Z]{3}(,([0-9a-zA-Z]{3}))*$'
const CHARNUM3COMMAerr = 'field must contain 3 character alpha-numeric value seperated by a comma'

function formatAWBNo(awb) {
  let result = ''
  if(awb) {
    var b = '-'
    var position = 3
    result = [awb.slice(0, position), b, awb.slice(position)].join('')
  }
  return result
}
function isValidULD (ULD_DISPO) {
  return ULD_DISPO === 'BREAKDOWN' || ULD_DISPO === 'MOVE AS THRU UNIT' || ULD_DISPO === 'KEEP INTACT FOR DELIVERY'
}
function getProdCode(awbs, priorityData) {
  let prodCodes = []
  if(awbs?.length > 0) {
    if(awbs.length === 1) {
      return awbs[0].awb.prod_code
    } else {
      awbs.map((awb) => {
        if(awb?.awb?.prod_code) prodCodes.push(awb.awb.prod_code)
      })

      if(priorityData?.length === 0) return prodCodes[0]
      else {
        let topPriority = { name: '' }

        let checkedData = []
        for(var i=0;i<prodCodes.length;i++) {
          for(var j=0;j<priorityData.length;j++) {
            if(prodCodes[i] === priorityData[j].p_code) {
              checkedData.push(priorityData[j])
              break
            }
          }
        }

        for(var a=0;a<prodCodes.length;a++) {
          for(var b=0;b<checkedData.length;b++) {
            let object = checkedData[b]
            let top = checkedData[0].priority

            if(prodCodes[a] === object.p_code && object.priority <= top) {
              topPriority.name = object.p_code
            }
          }
        }
        return topPriority.name
      }
    }
  } else {
    return ''
  }
}

function getTimeDifferenceInMinutes (difference) {
  if(difference) {
    return moment().diff(difference, 'minutes')
  } else return 0
}

class Warehouse extends Component {
  constructor(props) {
    super(props)
    this.state = {
      station: new Cookies().get('station'),
      role: new Cookies().get('role'),
      user_id: new Cookies().get('id'),
      logintype: getCookie(COOKIE.LOGINTYPE),
      timezone_cty: timezone.find((item) => item.value === new Cookies().get('station'))?.zone || 'America/New_York',
      clock: '',
      intervalID: null,
      servererror: false,
      is_fetching: false,
      loader: false,
      amber: 0,
      workArea: '',
      workAreaType: '',
      tempWorkArea: '',
      tempWorkAreaType: '',
      cargoType: '',
      prodType: [],
      locationType: '',
      data: [],
      displayData: [],
      workAreaData: [],
      stationsData: [],
      systemParameters: [],
      prodPriorityData: [],
      codeList: [],
      codeListDisplay: [],
      locationList: [],
      defaultSystemParameters: [],
      isListening: false,
      filterChecked: false,
      filterValue: '',
      exclusionArray: [],
      exclusionList: [],
      exclusionSearch: '',
      is_dom: '',

      tempSelCargoList: '',
      tempSelProductList: [],
      tempSelLocationList: '',
      tempSelExclusionList: [],
      tempSelWorkArea: '',
      tempSelWorkAreaType: '',
      tempSelfilterChecked: null,
      tempSelfilterValue: '',
      tempIs_dom: '',

      spinnerForULDs: false,
      spinnerForEvents: false,
      visible: false,
      warehouseFilter: {},
      searchSelect: false,
      searchValue: '',
      wasSearched: false,
      is_search: false,

      hiddenInterval: null,
      hiddenMoment: null,
    }
    this._queue = []
    this._isBusy = false
  }
  //clock added to dashboard 
  clockTimerLogic = () => {
    this.setState({ clock: moment().tz(this.state.timezone_cty).format('DD MMM YYYY | hh:mm A') })
  }
    
  clockTimerInterval = () => {
    let clockInterval = setInterval(() => {
      this.clockTimerLogic()
    }, 60000)
    this.setState({ intervalID: clockInterval })
  }
  
  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search)
    // const work_area = queryParams.get('work_area')
    // const type = queryParams.get('type')
    const filter = queryParams.get('filter')
    const value = queryParams.get('value')
    // eslint-disable-next-line no-console
    console.log(window.location.search, 'check')
    this.setState({ clock: moment().tz(this.state.timezone_cty).format('DD MMM YYYY | hh:mm A') }, () => {
      this.clockTimerLogic()
      this.clockTimerInterval()
    })

    pageHandler(window.location.pathname, getCookie(COOKIE.ROLE))
    // const system_params_events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${this.state.station}`)
    setInterval(() => this.setState({ hiddenInterval: this.handlePageVisibility() }), 10000)
    const warehouse_events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=bd&stn=${this.state.station}`)

    warehouse_events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
        if(!this.state.is_search){
          this.enqueue(myFakeAsyncJob)
        }
      }
    }
    warehouse_events.onerror = (error) => {
      if(error?.type === 'error') {
        this.setState({ servererror : true })
        window.location.href = `/breakdown-dashboard?work_area=${this.state.workArea}&type=${this.state.tempSelWorkAreaType}&filter=${this.state.tempSelfilterChecked}&value=${this.state.tempSelfilterValue}`
      }
    }

    if(filter) {
      this.setState({
        filterChecked: filter === 'false' ? false : true,
        filterValue: value,
        tempSelfilterChecked: filter === 'false' ? false : true,
        tempSelfilterValue: value,
      }, () => this.fetchWorkAreas())
    } else {
      this.fetchWorkAreas()
    }
  }

  handlePageVisibility = () => {
    //HANDLE TAB VISIBILITY
    if(this.state.hiddenMoment === null && window.document.visibilityState === 'hidden') {
      this.setState({ hiddenMoment: moment().format('YYYY-MM-DD HH:mm:ss') })
    } else if(window.document.visibilityState === 'visible' && (this.state.hiddenMoment && getTimeDifferenceInMinutes(this.state.hiddenMoment) < 5)) {
      this.setState({ hiddenMoment: null })
    }
    if((this.state.hiddenMoment && (getTimeDifferenceInMinutes(this.state.hiddenMoment) > 5)) && this._queue?.length > 2) {
      this.refreshPage()
    }
  }

  componentWillUnmount() {
    this.setState({ hiddenInterval: clearInterval(this.state.hiddenInterval) })
  }

  refreshPage = () => {
    window.location.href = `/breakdown-dashboard?work_area=${this.state.workArea}&type=${this.state.tempSelWorkAreaType}&filter=${this.state.tempSelfilterChecked}&value=${this.state.tempSelfilterValue}`
  }

  eventList = (parsedData) => {
    if(parsedData?.TYPE === 'ULD_UPDATE' && parsedData?.Msg === 'ULD UPDATE'){
      this.updateULDOnEvent(parsedData.DETAILS)
    }
    else if(parsedData?.TYPE === 'ULD_UPDATE' && parsedData?.msg === 'FSU') {
      this.processFSUEvent(parsedData.DETAILS)
    }
    else if(parsedData?.TYPE === 'ULD_UPDATE' && parsedData?.msg === 'status') {
      this.spliceULDOnStatusEvent(parsedData.DETAILS)
    }
    else if(parsedData?.TYPE === 'AWB_UPDATE') {
      this.processAWBUpdateEvent(parsedData.DETAILS)
    }
    else if(parsedData?.TYPE === 'BOOKING_UPDATE') {
      this.processBookingUpdateEvent(parsedData.DETAILS)
    }
    else if(parsedData?.TYPE === 'DEFAULT_HANDLING_TIMES') {
      this.fetchDefaultSystemParameters('EVENT')
    }
    else if(parsedData?.TYPE === 'WORK_AREA') {
      this.fetchWorkAreas('EVENT')
    }
    else if(parsedData?.TYPE === 'STATIONS') {
      this.fetchStations('EVENT')
    }
    else if(parsedData?.TYPE === 'STATION_DETAILS') {
      this.fetchAmberValue('EVENT')
    }
    else if(parsedData?.TYPE === 'HANDLING_TIMES') {
      this.fetchSystemParameters('EVENT')
    }
    else if(parsedData?.TYPE === 'PRODUCT_PRIORITY') {
      this.fetchProductPriority('EVENT')
    }
    else if(parsedData?.TYPE === 'COUNTDOWN') {
      this.handledAmberTrigger(parsedData?.record, parsedData?.type2, parsedData?.flag)
    }
  }

  /*************************************************************** */

  enqueue = (job) => {
    // we'll wrap the job in a promise and include the resolve and reject functions in the job we'll enqueue, so we can control when we resolve and execute them sequentially
    new Promise((resolve, reject) => {
      this._queue.push({
        job,
        resolve,
        reject
      })
    })
    // we'll add a nextJob function and call it when we enqueue a new job; we'll use _isBusy to make sure we're executing the next job sequentially
    this.nextJob()
  }
  nextJob = () => {
    if (this._isBusy) return
    const next = this._queue.shift()
    // if the array is empty shift() will return undefined
    if (next) {
      this.setState({ spinnerForEvents: true })
      this._isBusy = true
      next
        .job()
        .then((value) => {
          if(!this.state.is_search){
            this.eventList(value)
          }
          next.resolve(value)
          this._isBusy = false
          this.nextJob()
        })
        .catch((error) => {
          this.setState({ spinnerForEvents: false })
          next.reject(error)
          this._isBusy = false
          this.nextJob()
        })
    } else {
      this.setState({ spinnerForEvents: false })
    }
  }
  getFakeAsyncJob = (item) => {
    return () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(item)
        }, 600)
      })
  }

  /*************************************************************** */

  updateULDOnEvent = async (DETAILS) => {
    let ULDs = cloneDeep(this.state.data)
    let uld_index = ULDs?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))

    if(uld_index > -1) {
      //IF OLD AND NEW IS SAME - UPDATE ULD
      if((ULDs[uld_index]?.deliver_to === DETAILS?.old_deliver_to && ULDs[uld_index]?.deliver_to === DETAILS?.new_deliver_to)) {
        if(isValidULD(DETAILS?.uld_dispo?.toUpperCase())) {
          await Http.get(`ulds?id=${parseInt(DETAILS?.uld_id)}`, { headers: '' }).then((resp) => {
            if(!this.state.is_search){
              if (resp && resp?.data && resp?.data?.data?.length > 0/* && resp?.data?.data[0]?.blk === false*/) {
                if(resp?.data?.data[0]?.status?.toUpperCase() !== 'COMPLETED' && resp?.data?.data[0]?.is_completed === false) {
                  ULDs = this.state.data
                  uld_index = ULDs?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
                  if(uld_index > -1) {
                    let uld_object = this.processIndividualULD(resp?.data?.data[0])
                    if(uld_object) {
                      ULDs[uld_index] = uld_object
                    } else {
                      ULDs?.splice(uld_index, 1)
                    }
                    this.sort(ULDs, 'id', false)
                    this.sort(ULDs, 'handling_time', true)
                    this.setState({ data: [] }, () => this.setState({ data: ULDs }))

                    if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                      this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
                    }
                  } else {
                    if(ULDs && ULDs?.length > 0) {
                      let uld_object = this.processIndividualULD(resp?.data?.data[0])
                      if(uld_object) {
                        ULDs.push(uld_object)
                        this.sort(ULDs, 'id', false)
                        this.sort(ULDs, 'handling_time', true)
                        this.setState({ data: [] }, () => this.setState({ data: ULDs }))

                        if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                          this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
                        }
                      }
                    } else {
                      ULDs = []
                      let uld_object = this.processIndividualULD(resp?.data?.data[0])
                      if(uld_object) {
                        ULDs.push(uld_object)
                        this.sort(ULDs, 'id', false)
                        this.sort(ULDs, 'handling_time', true)
                        this.setState({ data: [] }, () => this.setState({ data: ULDs }))

                        if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                          this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
                        }
                      }
                    }
                  }
                } else if(resp?.data?.data[0]?.status?.toUpperCase() === 'COMPLETED' || resp?.data?.data[0]?.is_completed === true){
                  ULDs = this.state.data
                  uld_index = ULDs?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
                  if(uld_index > -1) {
                    ULDs.splice(uld_index, 1)
                    this.setState({ data: [] }, () => this.setState({ data: ULDs }))

                    if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                      this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
                    }
                  }
                }
              }
            }
          })
        } else {
          ULDs = this.state.data
          uld_index = ULDs?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))
          if(uld_index > -1) {
            ULDs.splice(uld_index, 1)
            this.setState({ data: [] }, () => this.setState({ data: ULDs }))

            if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
              this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
            }
          }
        }
      }
      //IF OLD IS SAME AND NEW DIFFERENT - DELETE ULD
      else if(isValidULD(DETAILS?.uld_dispo?.toUpperCase()) &&
      (ULDs[uld_index]?.deliver_to === DETAILS?.old_deliver_to && ULDs[uld_index]?.deliver_to !== DETAILS?.new_deliver_to)) {
        ULDs = this.state.data
        uld_index = ULDs?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))

        if(uld_index > -1) {
          ULDs.splice(uld_index, 1)
          this.setState({ data: [] }, () => this.setState({ data: ULDs }))

          if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
            this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
          }
        }
      }
      //IF OLD IS DIFFERENT AND NEW SAME - ADD ULD
      else if(isValidULD(DETAILS?.uld_dispo?.toUpperCase()) &&
      (ULDs[uld_index]?.deliver_to !== DETAILS?.old_deliver_to && ULDs[uld_index]?.deliver_to === DETAILS?.new_deliver_to)) {
        await Http.get(`ulds?id=${parseInt(DETAILS?.uld_id)}`, { headers: '' }).then((resp) => {
          if (resp && resp?.data && resp?.data?.data?.length > 0/* && resp?.data?.data[0]?.blk === false*/) {
            if(resp?.data?.data[0]?.status?.toUpperCase() !== 'COMPLETED' && resp?.data?.data[0]?.is_completed === false) {
              ULDs = this.state.data
              if(ULDs?.length > 0) {
                let uld_object = this.processIndividualULD(resp?.data?.data[0])
                if(uld_object) {
                  ULDs.push(uld_object)
                  this.sort(ULDs, 'id', false)
                  this.sort(ULDs, 'handling_time', true)
                  this.setState({ data: [] }, () => this.setState({ data: ULDs }))

                  if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                    this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
                  }
                }
              } else {
                ULDs = []
                let uld_object = this.processIndividualULD(resp?.data?.data[0])
                if(uld_object) {
                  ULDs.push(uld_object)
                  this.sort(ULDs, 'id', false)
                  this.sort(ULDs, 'handling_time', true)
                  this.setState({ data: [] }, () => this.setState({ data: ULDs }))

                  if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                    this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
                  }
                }
              }
            }
          }
        })
      }
    } else {
      if(isValidULD(DETAILS?.uld_dispo?.toUpperCase()) && this.state.workArea === DETAILS?.new_deliver_to) {
        if(this.state.tempSelWorkAreaType?.toUpperCase() !== DETAILS?.uld_type?.toUpperCase() && this.state.tempSelWorkAreaType?.toUpperCase() !== '') {
          //DO NOTHING
        } else {
          await Http.get(`ulds?id=${parseInt(DETAILS?.uld_id)}`, { headers: '' }).then((resp) => {
            if (resp && resp?.data && resp?.data?.data?.length > 0/* && resp?.data?.data[0]?.blk === false*/) {
              if(resp?.data?.data[0]?.status?.toUpperCase() !== 'COMPLETED' && resp?.data?.data[0]?.is_completed === false) {
                ULDs = this.state.data
                if(ULDs && ULDs?.length > 0) {
                  let uld_object = this.processIndividualULD(resp?.data?.data[0])
                  if(uld_object) {
                    ULDs.push(uld_object)
                    this.sort(ULDs, 'id', false)
                    this.sort(ULDs, 'handling_time', true)
                    this.setState({ data: [] }, () => this.setState({ data: ULDs }))

                    if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                      this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
                    }
                  }
                } else {
                  ULDs = []
                  let uld_object = this.processIndividualULD(resp?.data?.data[0])
                  if(uld_object) {
                    ULDs.push(uld_object)
                    this.sort(ULDs, 'id', false)
                    this.sort(ULDs, 'handling_time', true)
                    this.setState({ data: [] }, () => this.setState({ data: ULDs }))

                    if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                      this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
                    }
                  }
                }
              }
            }
          })
        }
      }
    }
  }
  processFSUEvent = async (DETAILS) => {
    let ULDs = cloneDeep(this.state.data)
    let uld_index = ULDs?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))

    if(uld_index > -1) {
      if(DETAILS?.status?.toUpperCase() === 'COMPLETED' || DETAILS?.is_completed === true) {
        ULDs.splice(uld_index, 1)
        this.setState({ data: [] }, () => this.setState({ data: ULDs }))

        if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
          this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
        }
      } else {
        await Http.get(`ulds?id=${parseInt(DETAILS?.uld_id)}`, { headers: '' }).then((resp) => {
          if(!this.state.is_search){
            ULDs = this.state.data
            uld_index = ULDs?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))

            if(uld_index > -1) {
              if (resp && resp?.data && resp?.data?.data?.length > 0 && (resp?.data?.data[0]?.status?.toUpperCase() !== 'COMPLETED' && resp?.data?.data[0]?.is_completed === false)) {
                if(isValidULD(DETAILS?.uld_dispo?.toUpperCase()) && this.state.workArea !== DETAILS?.new_deliver_to) {
                  ULDs.splice(uld_index, 1)
                  this.setState({ data: [] }, () => this.setState({ data: ULDs }))

                  if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                    this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
                  }
                } else if(this.state.tempSelWorkAreaType?.toUpperCase() !== DETAILS?.uld_type?.toUpperCase() && this.state.tempSelWorkAreaType?.toUpperCase() !== '') {
                  ULDs.splice(uld_index, 1)
                  this.setState({ data: [] }, () => this.setState({ data: ULDs }))

                  if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                    this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
                  }
                } else {
                  let uld_object = this.processIndividualULD(resp?.data?.data[0])
                  if(uld_object) {
                    ULDs[uld_index] = uld_object
                    this.sort(ULDs, 'id', false)
                    this.sort(ULDs, 'handling_time', true)
                    this.setState({ data: [] }, () => this.setState({ data: ULDs }))

                    if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                      this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
                    }
                  }
                }
              } else {
                ULDs.splice(uld_index, 1)
                this.setState({ data: [] }, () => this.setState({ data: ULDs }))

                if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                  this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
                }
              }
            }
          }
        })
      }
    } else {
      DETAILS.new_deliver_to = DETAILS?.work_area_fps
      DETAILS.old_deliver_to = DETAILS?.old_work_area_fps
      this.updateULDOnEvent(DETAILS)
    }
  }
  processAWBUpdateEvent = async (DETAILS) => {
    let ULDs = cloneDeep(this.state.data)
    let uld_index = ULDs?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))

    if(uld_index > -1) {
      await Http.get(`awbs?id=${parseInt(DETAILS?.goods_info_id)}`, { headers: '' }).then((resp) => {
        if(!this.state.is_search){
          if (resp && resp?.data && resp?.data?.data?.length > 0) {
            ULDs = this.state.data
            uld_index = ULDs?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))

            if(uld_index > -1) {
              let awb_index = ULDs[uld_index]?.awbs_in?.findIndex(p => p.id == parseInt(DETAILS?.goods_info_id))
              if(awb_index > -1) {
                ULDs[uld_index].awbs_in[awb_index] = this.processIndividualAWB(resp?.data?.data[0], awb_index)
                let uld_object = this.processIndividualULD(ULDs[uld_index])
                if(uld_object) {
                  ULDs[uld_index] = uld_object
                  this.sort(ULDs, 'id', false)
                  this.sort(ULDs, 'handling_time', true)
                  this.setState({ data: [] }, () => this.setState({ data: ULDs }))

                  if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                    this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
                  }
                }
              }
            }
          }
        }
      })
    }
  }
  processBookingUpdateEvent = async (DETAILS) => {
    let ULDs = cloneDeep(this.state.data)
    let uld_index = ULDs?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))

    if(uld_index > -1) {
      await Http.get(`ulds?id=${parseInt(DETAILS?.uld_id)}`, { headers: '' }).then((resp) => {
        if(!this.state.is_search){
          if (resp && resp?.data && resp?.data?.data?.length > 0 && (resp?.data?.data[0]?.status?.toUpperCase() !== 'COMPLETED' && resp?.data?.data[0]?.is_completed === false)) {
            ULDs = this.state.data
            uld_index = ULDs?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))

            if(uld_index > -1) {
              if(isValidULD(resp?.data?.data[0]?.uld_dispo?.toUpperCase())) {
                let uld_object = this.processIndividualULD(resp?.data?.data[0])
                if(uld_object) {
                  ULDs[uld_index] = uld_object
                  this.sort(ULDs, 'id', false)
                  this.sort(ULDs, 'handling_time', true)
                  this.setState({ data: [] }, () => this.setState({ data: ULDs }))

                  if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                    this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
                  }
                }
              } else {
                ULDs?.splice(uld_index, 1)
                this.setState({ data: [] }, () => this.setState({ data: ULDs }))

                if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                  this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
                }
              }
            }
          }
        }
      })
    }
  }
  spliceULDOnStatusEvent = (DETAILS) => {
    let ULDs = cloneDeep(this.state.data)
    let uld_index = ULDs?.findIndex(p => p.id == parseInt(DETAILS?.uld_id))

    if(uld_index > -1 && (DETAILS?.status?.toUpperCase() === 'COMPLETED' || DETAILS?.is_completed === true)) {
      ULDs?.splice(uld_index, 1)
      this.setState({ data: [] }, () => this.setState({ data: ULDs }))

      if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
        this.setState({ displayData: ULDs }, () => this.getFilteredData(true))
      }
    }
  }

  /**************************************************************************************************** */

  //FETCH WAREHOUSE DATA
  fetchWarehouseData = async (work_area, work_area_type, last_id, is_search = false) => {
    let temp_timestamp = moment().valueOf()
    this.setState({
      is_fetching: true,
      is_search: is_search,
      spinnerForULDs: true,
      latestTimestamp: temp_timestamp,
      // tempSelfilterChecked: false,
      loading: ((this.state.data?.length === 0) || (this.state.tempSelfilterChecked && this.state.tempSelfilterValue && this.state.displayData?.length === 0)) ? true: false
    })
    let url = ''
    let respdata = []

    if(is_search) {
      url = `break_down_list_tv?search=${this.state.searchValue}&limit=20`
    }
    else if(last_id) {
      url = `break_down_list_tv?work_area=${this.state.workArea}&type=${this.state.tempSelWorkAreaType}&last_id=${last_id}&limit=20`
    } else {
      url = `break_down_list_tv?work_area=${this.state.workArea}&type=${this.state.tempSelWorkAreaType}&limit=20`
    }
    let headers = {
      station: this.state.station,
      search: this.state.search
    }
    await Http.get(url, { headers }).then(resp => {
      if(this.state.latestTimestamp === temp_timestamp) {
        if(resp?.status === 200) {
          if (resp && resp?.data && resp?.data?.data?.length > 0) {
            respdata = cloneDeep(resp.data.data)
            if(is_search === false && ((this.state.workArea && work_area !== this.state.workArea) || (this.state.tempSelWorkAreaType && work_area_type !== this.state.tempSelWorkAreaType))){ // If work area was change, don't update or call next page
              //do nothing
            } else {
              let lastID = respdata[respdata.length - 1].id
              let uld_array = []
              respdata.map((item) => {
                let obj = this.processIndividualULD(item)
                if(obj) {
                  uld_array.push(obj)
                }
              })
              respdata = uld_array

              let { data } = this.state
              if(data?.length > 0 && last_id) {
                data = data?.concat(respdata)
              } else {
                data = respdata
              }
              //CHECK FOR DUPLICATE ENTRIES
              let newData = []
              data?.map((item) => {
                var id = newData.findIndex(p => p.id == parseInt(item.id))
                if(id > -1) {
                  //DO NOTHING
                } else {
                  newData.push(item)
                }
              })

              this.sort(newData, 'id', false)
              this.sort(newData, 'handling_time', true)
              this.setState({
                data: [],
                workArea: this.state.workArea,
                workAreaType: this.state.tempSelWorkAreaType,
                //loading: false,
              }, () => this.setState({ data: newData }, () => {
                if(!is_search){
                  if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
                    this.getFilteredData(false)
                  } else {
                    this.setState({
                      displayData: newData,
                      loading: false
                    }, () => this.exclusionFilter(false))
                  }
                } else {
                  this.setState({
                    displayData: newData,
                    loading: false,
                    spinnerForULDs: false,
                  })
                }
              }))
              if(!is_search){
                this.fetchWarehouseData(this.state.workArea, this.state.tempSelWorkAreaType, lastID, is_search)
              }
            }
          } else {
            this.setState({
              spinnerForULDs: false,
              loading: false,
              is_fetching: false
            }, () => {
              // if(!this.state.spinnerForULDs && (this.state.tempSelfilterChecked && this.state.displayData?.length === 0)) {
              if(( this.state.is_search && this.state.data && this.state.data.length>0 )|| (this.isOtherFilters() && this.state.displayData && this.state.displayData.length>0 ) || (this.state.data && this.state.data >0 )){
                notification.destroy()
                notification.error({
                  message: 'Failed',
                  description: 'No Ulds found',
                })
              }
            })
            if(!last_id) {
              this.setState({
                data: [],
                displayData: [],
                loading: false,
              }, () => {
                notification.destroy()
                notification.error({
                  message: 'Failed',
                  description: resp?.data?.message,
                })
              })
            }
          }
        } else if(resp?.data?.status === 'error') {
          this.fetchWarehouseData(work_area, work_area_type, last_id, is_search)
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true,
        spinnerForULDs: false,
      })
    })
  }
  //PROCESS DATA ON EVENT
  processData = () => {
    let uld_array = []
    this.state.data?.map((item) => {
      let obj = this.processIndividualULD(item)
      if(obj) {
        uld_array.push(obj)
      }
    })

    this.sort(uld_array, 'id', false)
    this.sort(uld_array, 'handling_time', true)
    this.setState({
      data: [],
      workArea: this.state.workArea,
      loading: false,
    }, () => this.setState({ data: uld_array }, () => {
      if(this.state.is_search){
        this.setState({ displayData: uld_array })
      } else {
        if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
          this.setState({ displayData: uld_array }, () => this.getFilteredData(false))
        }
      }
    }))
  }
  //FETCH RECOVERY ACTIONS DATA
  fetchWorkAreas = async () => {
    let callback = this.fetchWorkAreasFn
    let pageURL =  'work_areas'
    let headers = {
      page: PAGE.BREAKDOWN,
      station: this.state.station,
      module: 'inbound'
    }
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchWorkAreasFn = async(resp, event) => {
    if(!event) this.setState({ loading: true })
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if (respData && respData?.data?.length > 0) {
        let data = respData?.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })
        this.sort(data, 'id', false)
        this.sort(data, 'area_name', false)
        this.setState({
          workAreaData: data,
          tempWorkArea: this.state.workArea ? this.state.workArea : data[0]?.area_name,
          tempSelWorkArea: this.state.workArea ? this.state.workArea : data[0]?.area_name,
          tempWorkAreaType: this.state.tempSelWorkAreaType ? this.state.tempSelWorkAreaType : '',
          tempSelWorkAreaType: this.state.tempSelWorkAreaType ? this.state.tempSelWorkAreaType : '',
        })
      } else {
        this.setState({ workAreaData: [] })
        this.setState({ loading: false })
      }

      if(!event) {
        this.fetchDefaultSystemParameters()
      } else {
        this.processData()
      }
    }
  }
  //FETCH DEFAULT HANDLING TIMES
  fetchDefaultSystemParameters = async () => {
    let callback = this.fetchDefaultSystemParametersFn
    let pageURL =  `handling_times?default=true&station=${this.state.station}`
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchDefaultSystemParametersFn = async(resp, event) => {
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if (respData && respData?.data?.length > 0) {
        let data = respData?.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })
        this.setState({ defaultSystemParameters: data })
      } else {
        this.setState({ defaultSystemParameters: [] })
        this.setState({ loading: false })
      }

      if(!event) {
        this.fetchSystemParameters()
      } else {
        this.processData()
      }
    }
  }
  //FETCH HANDLING TIMES
  fetchSystemParameters = async () => {
    let callback = this.fetchSystemParametersFn
    let pageURL =  `handling_times?station=${this.state.station}`
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchSystemParametersFn = async(resp, event) => {
    if(!event) this.setState({ loading: true })
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if (respData && respData?.data?.length > 0) {
        let data = respData?.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })

        this.setState({ systemParameters: data })
      } else {
        this.setState({ systemParameters: [] })
        this.setState({ loading: false })
      }

      if(!event) {
        this.fetchStations()
      } else {
        this.processData()
      }
    }
  }
  //FETCH STATIONS
  fetchStations = async () => {
    let callback = this.fetchStationsFn
    let pageURL =  'stations'
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchStationsFn = async(resp, event) => {
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if (respData && respData?.data?.length > 0) {
        let data = respData?.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })

        this.setState({ stationsData: data })
      } else {
        this.setState({ stationsData: [] })
        this.setState({ loading: false })
      }

      if(!event) {
        this.fetchAmberValue()
      } else {
        this.processData()
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData.message,
      })
      this.setState({ loading: false })
    }
  }
  //FETCH AMBER VALUE
  fetchAmberValue = async () => {
    let callback = this.fetchAmberValueFn
    let pageURL =  `station_details?station=${this.state.station}`
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchAmberValueFn = async(resp, event) => {
    const respData = await resp.json()
    if (resp?.status === 200) {
      if(respData?.data?.length > 0) {
        this.setState({ amber: respData.data[0]?.indicator_time || 0 })
      }
      if(!event) {
        this.fetchProductPriority()
      } else {
        this.processData()
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData?.message,
      })
      this.setState({ loading: false })
    }
  }
  //FETCH PRODUCT PRIORITY
  fetchProductPriority = async () => {
    let callback = this.fetchProductPriorityFn
    let pageURL =  `products_priority?station=${this.state.station}`
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchProductPriorityFn = async(resp, event) => {
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if(respData && respData.data[0] && respData.data[0].prod_prior_list.length>0){
        this.setState({ prodPriorityData : respData.data[0].prod_prior_list })
      }
      if(!event) {
        this.fetchFilters(false)
      } else {
        this.processData()
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData?.message,
      })
      this.setState({ loading: false })
    }
  }
  //FETCH USER FILTERS
  fetchFilters = async (isAPICall) => {
    await Http.get(`/page_filter/${this.state.user_id}`).then(async (data) => {
      if (data && data?.status === 200) {
        let res_data = data?.data?.data?.page_filters?.warehouse_filter && data?.data?.data?.page_filters?.warehouse_filter[this.state.station]
        if(res_data) {
          this.setState({
            warehouseFilter: data?.data?.data?.page_filters?.warehouse_filter,
            workArea: res_data?.workarea || this.state.tempWorkArea,
            workAreaType: res_data?.type || this.state.tempWorkAreaType,
            cargoType: res_data?.cargo ? res_data?.cargo : res_data?.cargo === '' ? '' : this.state.cargoType,
            prodType: res_data?.product || this.state.prodType,
            locationType: res_data?.location ? res_data?.location : res_data?.location === '' ? '' : this.state.locationType,
            exclusionArray: res_data?.exclusion || this.state.exclusionArray,
            exclusionList: res_data?.exclusion || this.state.exclusionList,
            is_dom: res_data?.is_dom || this.state.is_dom,
            filterChecked: res_data?.filterChecked || this.state.filterChecked,
            filterValue: res_data?.filterValue || this.state.filterValue,

            tempSelCargoList: res_data?.cargo ? res_data?.cargo : res_data?.cargo === '' ? '' : this.state.tempSelCargoList,
            tempSelProductList: res_data?.product || this.state.tempSelProductList,
            tempSelLocationList: res_data?.location ? res_data?.location : res_data?.location === '' ? '' : this.state.tempSelLocationList,
            tempSelExclusionList: res_data?.exclusion || this.state.tempSelExclusionList,
            // tempSelWorkArea: res_data?.workarea || this.state.tempSelWorkArea,
            tempSelWorkAreaType: res_data?.type || this.state.tempSelWorkAreaType,
            tempIs_dom: res_data.is_dom || this.state.is_dom,
            tempSelfilterChecked: res_data?.filterChecked || this.state.filterChecked,
            tempSelfilterValue: res_data?.filterValue || this.state.filterValue,
          }, () => {
            if(data?.data?.data?.page_filters?.warehouse_filter?.exclusion?.length > 0) {
              this.setState({ codeListDisplay: this.state.codeList?.filter((item) => !data?.data?.data?.page_filters?.warehouse_filter?.exclusion?.includes(item)) })
            } else {
              this.setState({ codeListDisplay: this.state.codeList })
            }
            this.getFilteredData(false)
          })
        } else {
          this.setState({
            warehouseFilter: data?.data?.data?.page_filters?.warehouse_filter || {},
            workArea: this.state.tempWorkArea,
            workAreaType: this.state.tempWorkAreaType,
          })
        }
        if(!isAPICall) {
          this.fetchWarehouseData(this.state.workArea, this.state.tempSelWorkAreaType, null, this.state.is_search)
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: data?.data?.message,
        })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({ servererror : true })
    })
  }
  //CHECK AWB & RETURN AWBS AND REMAINING PCS
  awbCheck = (awbs) => {
    let filtered_awbs = []
    let total_pcs = 0
    if(awbs?.length > 0) {
      awbs?.map((awb, index) => {
        total_pcs = total_pcs + awb?.pcs
        awbs[index].remaining_pcs = awb?.pcs - (awb?.break_down?.pcs || 0)
      })

      if(this.state.tempSelWorkAreaType?.toUpperCase() === 'LOCAL' && !this.state.is_search) {
        filtered_awbs = awbs.filter((awb) => awb?.dest === this.state.station && (awb?.status?.toUpperCase() !== 'COMPLETED' && awb?.is_completed === false))
      } else if(this.state.tempSelWorkAreaType?.toUpperCase() === 'TRANSIT' && !this.state.is_search) {
        filtered_awbs = awbs.filter((awb) => awb?.dest !== this.state.station && (awb?.status?.toUpperCase() !== 'COMPLETED' && awb?.is_completed === false))
      } else {
        filtered_awbs = awbs.filter((awb) => awb?.status?.toUpperCase() !== 'COMPLETED' && awb?.is_completed === false)
      }
    }
    return {
      awbs: filtered_awbs,
      pcs: total_pcs,
    }
  }
  //CHECK FOR ULD TYPE
  // checkULDType = (uld) => {
  //   let type = 'ULD'
  //   if(uld?.blk) {
  //     type = 'BLK'
  //   } else {
  //     if(uld?.info?.remark?.toUpperCase()?.includes('SLU')) {
  //       type = 'SLU'
  //     } else if(!uld?.info?.remark?.toUpperCase()?.includes('SLU') && uld?.uld_dispo?.toUpperCase() !== 'BREAKDOWN') {
  //       type = 'PTU'
  //     }
  //   }
  //   return type
  // }
  checkULDType = (uld) => {
    let type = 'ULD'
    if(uld?.blk && (!uld?.uld_no?.startsWith('C') && !uld?.uld_no?.endsWith('#'))) {
      type = 'BLK'
    } else {
      if(uld?.info?.remark?.toUpperCase()?.includes('SLU')) {
        type = 'SLU'
      } else if(!uld?.info?.remark?.toUpperCase()?.includes('SLU') && uld?.uld_dispo?.toUpperCase() !== 'BREAKDOWN' && uld?.master) {
        type = 'PTU'
      } else if(uld?.uld_no?.startsWith('C') || uld?.uld_no?.endsWith('#')) {
        type = 'CART'
      }
    }
    return type
  }
  //UPDATE INDIVIDUAL ULD
  processIndividualULD = (uld) => {
    //GET FLIGHT DOM/INTL
    uld.dom_intl = 'INTL'
    let details = this.state.stationsData?.find((stn) => stn?.code === uld?.flights_out?.dest?.arriv) || ''
    if(details) {
      if(country.includes(details?.country?.toUpperCase())) {
        uld.dom_intl = 'DOM'
      }
    }
    uld.pcs = 0
    uld.type = this.checkULDType(uld)
    uld.prod_code = getProdCode(uld?.awbs_in, this.state.prodPriorityData)
    if(uld?.prod_code) {
      this.pushProdCode(uld.prod_code)
    }
    if(uld?.location) {
      this.pushLocation(uld.location)
    }
    let returned_object = this.awbCheck(uld.awbs_in)
    uld.awbs_in = returned_object?.awbs
    uld.total_pcs = returned_object?.pcs

    uld.station = ''
    uld.flt = ''
    uld.org = ''
    uld.sameflt = true
    if(uld.awbs_in?.length > 0) {
      uld.awbs_in.map((awb, awb_index) => {
        awb = this.processIndividualAWB(awb, awb_index)
        uld.pcs = uld.pcs + awb.pcs - (awb?.break_down?.pcs || 0)

        //IF ULD DELIVER TO IS EMPTY UPDATE AWB STATUS COLOR/MESSAGE
        // if(!uld?.work_areas_id) {
        //   awb.handling_time_color = 'red'
        // }

        if(uld?.type === 'BLK') {
          uld.station = awb?.dest
        }
        if(awb.org){
          uld.org = awb.org
        }
        if(awb?.flights_in?.flight_no) {
          if(uld.flt && uld.flt !== '' && uld.flt !== awb?.flights_in?.flight_no ){
            uld.sameflt = false
          }
          uld.flt = awb?.flights_in?.flight_no
        }
      })
      uld.awbs_in.map((awb) => {
        awb.dontshowflt = !uld.type === 'CART' ? true : uld.sameflt
        if(awb.ht_minutes === 0) {
          awb.ht_minutes = null
        }
      })

      this.sort(uld.awbs_in, 'id', false)
      this.sort(uld.awbs_in, 'handling_time', true)
      //TO SET EARLIEST ULD TIME ON FRONT END
      let least_awb = getLeastAWB(uld.awbs_in)
      uld.show_handling_time_after_tta = least_awb.flag
      uld.handling_time_as_timestamp = least_awb.time
      uld.handling_time = least_awb.ht
      uld.amber_countdown = least_awb?.amber_countdown
      uld.amber_flag = least_awb?.amber_flag
      uld.handling_time_color = uld.awbs_in.filter((item) => item.handling_time_color !== 'green')?.length > 0 ? false : true
    } else {
      uld.show_handling_time_after_tta = true
      uld.handling_time_as_timestamp = '00:00'
      uld.handling_time = moment()
      uld.amber_countdown = moment()
      uld.amber_flag = true
      uld.handling_time_color = 'grey'
    }
    return uld?.awbs_in?.length > 0 && uld?.type === 'BLK' || uld?.awbs_in?.length > 0 ? uld : null
  }
  //PUSH PROD CODE FOR FILTER
  pushProdCode = (code) => {
    let prodCodeList = this.state.codeList
    let index = prodCodeList?.findIndex((p) => p === code)
    if(index === -1) {
      prodCodeList.push(code)
      this.setState({
        codeList: [],
        codeListDisplay: [],
      }, () => this.setState({
        codeList: prodCodeList,
        codeListDisplay: prodCodeList,
      }))
    }
  }
  //PUSH LOCATION FOR FILTER
  pushLocation = (location) => {
    let workLocationList = this.state.locationList
    let index = workLocationList?.findIndex((p) => p.text === location)
    if(index === -1) {
      workLocationList.push({
        text: location,
        value: location,
      })
      this.setState({ locationList: [] }, () => this.setState({ locationList: workLocationList }))
    }
  }
  //UPDATE INDIVIDUAL AWB
  processIndividualAWB = (awb, awb_index) => {
    awb.key = awb_index
    handlingTimes(awb,
      this.state.systemParameters && this.state.systemParameters.filter((item) => item.station === this.state.station),
      this.state.defaultSystemParameters && this.state.defaultSystemParameters.filter((item) => item.station === this.state.station), this.state.stationsData, 'BREAKDOWN')

    //MAKE HT COLOR RED IF COUNTDOWN HAS REACHED 00:00
    if(moment().valueOf() > moment(awb?.handling_time)?.valueOf()) {
      awb.handling_time_color = 'red'
      awb.ht_minutes = 0
    }

    awb.amber_countdown = moment(awb?.handling_time_after_tta).subtract(this.state.amber, 'minutes')

    if(moment(awb.handling_time).valueOf() < moment().valueOf()) {
      awb.amber_flag = false
    } else {
      awb.amber_flag = moment(awb.amber_countdown).valueOf() < moment().valueOf() ? true : false
    }

    return awb
  }
  //SORT
  sort = (array, value, is_date) => {
    array && array.sort(function(a, b) {
      var keyA = is_date ? moment(a[value]) : a[value]
      var keyB = is_date ? moment(b[value]) : b[value]
      // Compare the fields
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })
  }
  //REDIRECT LOGIC
  handleRedirect = () => {
    if(this.state.role?.toUpperCase() === 'WAREHOUSE TV') {
      logout()
    } else {
      pageHandler('', getCookie(COOKIE.ROLE))
    }
  }
  //PARSE COUNTDOWN DATA AND SEND TO QUEUE
  createCountdownPromise = (record, type, flag) => {
    if(moment(record.handling_time).add(5, 'seconds').valueOf() > moment().valueOf()) {
      let parsedData = {
        TYPE: 'COUNTDOWN',
        record: record,
        type2: type,
        flag: flag,
      }
      const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
      this.enqueue(myFakeAsyncJob)
    }
  }
  //TRIGGER AMBER COLOR FOR ULD/AWB
  handledAmberTrigger = (record, type, flag) => {
    let data = cloneDeep(this.state.data)
    if(type === 'ULD') {
      let uld_index = data?.findIndex((p) => p.id === record.id)

      if(uld_index > -1) {
        //MAKE HT COLOR RED IF COUNTDOWN HAS REACHED 00:00
        if(flag === false) {
          data[uld_index].handling_time_color = false
        }
        data[uld_index].amber_flag = flag
        this.setState({ data: data })

        if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
          this.setState({ displayData: data }, () => this.getFilteredData(false))
        }
      }
    } else if(type === 'AWB') {
      let uld_index = data?.findIndex((p) => p.id === record.uld_in)

      if(uld_index > -1) {
        let awb_index = data[uld_index]?.awbs_in?.findIndex((p) => p.id === record.id)
        if(awb_index > -1) {
          //MAKE HT COLOR RED IF COUNTDOWN HAS REACHED 00:00
          if(flag === false) {
            data[uld_index].awbs_in[awb_index].handling_time_color = 'red'
          }
          data[uld_index].awbs_in[awb_index].amber_flag = flag
          this.setState({ data: data })

          if((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters()) {
            this.setState({ displayData: data }, () => this.getFilteredData(false))
          }
        }
      }
    }
  }
  //FILTER ULDS BY TIME
  getFilteredData = (event) => {
    if(this.state.is_search){
      this.fetchWarehouseData(this.state.workArea, this.state.workAreaType, null, this.state.is_search)
      this.setState({ displayData: [] }, () => this.setState({ displayData: this.state.data }, () => this.exclusionFilter(false)))
    } else {

      if(this.state.tempSelfilterValue) {
        let filtered_data = this.state.data?.filter((item) => moment(item.handling_time).diff(moment(), 'minutes') >= this.state.tempSelfilterValue)
        if(filtered_data?.length > 0) {
          filtered_data = this.otherFilters(filtered_data)
          if(filtered_data?.length > 0) {
            this.setState({
              loading: false,
              displayData: [],
            }, () => this.setState({ displayData: filtered_data }, () => this.exclusionFilter(false)))
            if((!this.state.loading && !this.state.spinnerForEvents) && filtered_data?.length === 0 && !event && !this.state.is_fetching) {
              notification.destroy()
              notification.error({
                message: 'Failed',
                description: 'No Ulds found',
              })
            }
          } else {
            this.setState({ displayData: [] }, () => {
              this.setState({ displayData: filtered_data })
              if((!this.state.loading && !this.state.spinnerForEvents) && filtered_data?.length === 0 && !event && !this.state.is_fetchingt) {
                notification.destroy()
                notification.error({
                  message: 'Failed',
                  description: 'No Ulds found',
                })
              }
            })
          }
        } else {
          this.setState({ displayData: [] }, () => {
            this.setState({ displayData: filtered_data }, () => this.exclusionFilter(false))
            if((!this.state.loading && !this.state.spinnerForEvents) && filtered_data?.length === 0 && !event && !this.state.is_fetching) {
              notification.destroy()
              notification.error({
                message: 'Failed',
                description: 'No Ulds found',
              })
            }
          })
        }
      } else if(this.state.tempSelCargoList || this.state.tempSelProductList?.length > 0 || this.state.tempSelLocationList || this.state.tempIs_dom) {
        let filtered_data = this.otherFilters(this.state.data)
        this.setState({ displayData: [] }, () => this.setState({ displayData: filtered_data }, () => this.exclusionFilter(false)))
        if((!this.state.loading && !this.state.spinnerForEvents) && filtered_data?.length === 0 && !event) {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: 'No Ulds found',
          })
        }
      } else {
        this.setState({ displayData: [] }, () => this.exclusionFilter(false))
      }
    }
  }

  renderFilterBlock = () => {
    if(this.state.is_search){
      return (
        <><br />
          <Row className='mt-1vw pl-2vw sel-flt-rw'>
            <b> Search: </b><span>{this.state.searchValue}</span>
          </Row>
        </>
      )
    }
    return (
      <><br />
        <Row className='mt-1vw pl-2vw sel-flt-rw'>
          <b> Workarea</b>: {this.state.workArea}
          <b> Type</b>: {this.state.tempSelWorkAreaType?.toUpperCase() === 'LOCAL' ? 'Local' : this.state.tempSelWorkAreaType?.toUpperCase() === 'TRANSIT' ? 'Transit' : 'ALL'}
          <b> Cargo</b>: {this.state.cargoType || 'ALL'}
          {this.state.is_dom && <span><b> DOM/INTL</b>: {this.state.is_dom || 'ALL'}</span>}
          {this.state.locationType && <span><b> Location: </b>{this.state.locationType}</span>}
        </Row>
      </>
    )
  }
  //OTHER FILTERS
  otherFilters = (filtered_data) => {
    if(this.state.tempSelCargoList && this.state.tempSelProductList?.length > 0 && this.state.tempSelLocationList) {
      return filtered_data?.filter((item) => item?.type === this.state.tempSelCargoList && this.state.tempSelProductList?.includes(item?.prod_code) && item?.location === this.state.tempSelLocationList)
    } else if(this.state.tempSelCargoList && this.state.tempSelProductList?.length > 0) {
      return filtered_data?.filter((item) => item?.type === this.state.tempSelCargoList && this.state.tempSelProductList?.includes(item?.prod_code))
    } else if(this.state.tempSelCargoList && this.state.tempSelLocationList) {
      return filtered_data?.filter((item) => item?.type === this.state.tempSelCargoList && item?.location === this.state.tempSelLocationList)
    } else if (this.state.tempSelProductList?.length > 0 && this.state.tempSelLocationList) {
      return filtered_data?.filter((item) => this.state.tempSelProductList?.includes(item?.prod_code) && item?.location === this.state.tempSelLocationList)
    } else if(this.state.tempSelCargoList) {
      return filtered_data?.filter((item) => item?.type === this.state.tempSelCargoList)
    } else if(this.state.tempSelProductList?.length > 0) {
      return filtered_data?.filter((item) => this.state.tempSelProductList?.includes(item?.prod_code))
    } else if(this.state.tempSelLocationList) {
      return filtered_data?.filter((item) => item?.location === this.state.tempSelLocationList)
    }else if(this.state.tempIs_dom) {
      return filtered_data?.filter((item) => item?.dom_intl === this.state.tempIs_dom)
    } else {
      return filtered_data
    }
  }
  //SAVE USER FILTERS
  saveFilters = async (isAPICall) => {
    let exclusions = this.state.exclusionArray?.toString()

    if(exclusions && !exclusions?.match(CHARNUM3COMMA)) {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: CHARNUM3COMMAerr,
      })
    } else {
      let warehouse_filter = cloneDeep(this.state.warehouseFilter)
      warehouse_filter[this.state.station] = {
        workarea: this.state.workArea,
        type: this.state.workAreaType,
        cargo: this.state.cargoType,
        product: this.state.prodType,
        location: this.state.locationType,
        exclusion: this.state.exclusionArray,
        is_dom: this.state.is_dom,
        filterChecked: this.state.filterChecked,
        filterValue: this.state.filterValue,
      }
      let formdata = { warehouse_filter: warehouse_filter }
      await Http.put(`/page_filter/${this.state.user_id}`, formdata).then(async (data) => {
        if (data && data?.status === 200) {
          this.fetchFilters(!isAPICall)
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({ servererror : true })
      })
    }
  }
  isOtherFilters = () => {
    return this.state.tempSelCargoList || this.state.tempSelProductList?.length > 0 || this.state.tempSelLocationList || this.state.tempIs_dom
  }
  clearFilters = () => {
    this.setState({
      cargoType: '',
      prodType: [],
      locationType: '',
      exclusionArray: [],
      exclusionList: [],
      tempSelCargoList: '',
      tempSelProductList: [],
      tempSelLocationList: '',
      tempSelExclusionList: [],
      filterChecked: false,
      filterValue: '',
      tempIs_dom: '',
      is_dom: '',
      workAreaType: ''
    }, () => this.saveFilters(false))
  }
  showDrawer = () => {
    this.setState({
      visible: true,
      cargoType: this.state.tempSelCargoList,
      prodType: cloneDeep(this.state.tempSelProductList),
      locationType: this.state.tempSelLocationList,
      exclusionArray: this.state.tempSelExclusionList,
      // workArea: this.state.tempSelWorkArea,
      workAreaType: this.state.tempSelWorkAreaType,
      filterChecked: this.state.tempSelfilterChecked,
      filterValue: this.state.tempSelfilterValue,
      is_dom: this.state.tempIs_dom
    })
  }
  onClose = () => {
    this.setState({
      visible: false,
      cargoType: this.state.tempSelCargoList,
      prodType: cloneDeep(this.state.tempSelProductList),
      locationType: this.state.tempSelLocationList,
      exclusionArray: this.state.tempSelExclusionList,
      // workArea: this.state.tempSelWorkArea,
      workAreaType: this.state.tempSelWorkAreaType,
      filterChecked: this.state.tempSelfilterChecked,
      filterValue: this.state.tempSelfilterValue,
      is_dom: this.state.tempIs_dom
    })
  }
  handleOnSelectExclusion = (value) => {
    let exclusions = cloneDeep(this.state.exclusionArray)
    if(value && !exclusions?.includes(value)) {
      exclusions.push(value)
      this.setState({
        exclusionSearch: '',
        exclusionArray: exclusions,
      })

      if(exclusions?.length > 0) {
        this.setState({ codeListDisplay: this.state.codeList?.filter((item) => !exclusions?.includes(item)) })
      } else {
        this.setState({ codeListDisplay: this.state.codeList })
      }
    } else {
      this.setState({ exclusionSearch: '' })
    }
  }
  popExclusion = (value) => {
    let exclusions = cloneDeep(this.state.exclusionArray)
    let index = exclusions?.findIndex((p) => p === value)
    if(index > -1) {
      exclusions?.splice(index, 1)
      this.setState({ exclusionArray: exclusions })

      if(exclusions?.length > 0) {
        this.setState({ codeListDisplay: this.state.codeList?.filter((item) => !exclusions?.includes(item)) })
      } else {
        this.setState({ codeListDisplay: this.state.codeList })
      }
    }
  }
  //EXCLUDE PRODUCT CODE FILTER
  exclusionFilter = (action) => {
    if(this.state.is_search){
      this.fetchWarehouseData(null, null, null, this.state.is_search)
    } else {
      if(!this.state.is_search){
        let ULDs = cloneDeep(this.state.displayData)
        let exclusions = this.state.exclusionArray
        let arr = []
        if(action) {
          this.setState({
            visible: false,
            tempSelfilterChecked: this.state.filterChecked,
            tempSelfilterValue: this.state.filterValue,
            tempSelLocationList: this.state.locationType,
            tempSelCargoList: this.state.cargoType,
            tempSelProductList: this.state.prodType,
            tempIs_dom: this.state.is_dom,
          }, () => {
            if(/*this.state.tempSelWorkArea !== this.state.workArea || */this.state.tempSelWorkAreaType !== this.state.workAreaType) {
              this.setState({
                // tempSelWorkArea: this.state.workArea,
                tempSelWorkAreaType: this.state.workAreaType,
                loading: true,
                locationList: [],
                locationType: '',
                codeList: [],
                prodType: [],
                data: [],
                displayData: [],
              },() => this.fetchWarehouseData(this.state.workArea, this.state.workAreaType, null, this.state.is_search))
            }
            // else {
            //   this.getFilteredData(false)
            // }
            this.saveFilters(false)
          })
        } else {
          arr = ULDs.filter(function(el) {
            return !exclusions?.includes(el?.prod_code)
          })
          this.setState({
            displayData: [],
            //visible: false,
          }, () => this.setState({ displayData: arr }))

          if(exclusions?.length > 0) {
            this.setState({ codeListDisplay: this.state.codeList?.filter((item) => !exclusions?.includes(item)) })
          } else {
            this.setState({ codeListDisplay: this.state.codeList })
          }
        }
      }
    }
  }

  // AWB SEARCH
  setSearchValue (e){
    this.setState({ searchValue: e.target.value },()=>{
      if(this.state.wasSearched && !this.state.searchValue){
        this.setState({
          wasSearched: false,
          loading: true,
          data: [],
          displayData: [],
        },()=> this.fetchWarehouseData(this.state.workArea, this.state.workAreaType, null, this.state.wasSearched))
      }
    })
  }
  onSearch = (e ) => {
    if(!e){
      this.setState({ searchSelect: !this.state.searchSelect })
    }
    if(e){
      this.setState({
        wasSearched: true,
        data: [],
        displayData: [],
      },()=> this.fetchWarehouseData(null, null, null, this.state.wasSearched))
    } else {
      let tempwasSearched = cloneDeep(this.state.wasSearched)
      this.setState({
        wasSearched: false,
        searchValue: e
      },()=> {
        if(tempwasSearched){
          this.setState({
            loading: true,
            data: [],
            displayData: [],
          },()=> this.fetchWarehouseData(this.state.workArea, this.state.workAreaType, null, this.state.wasSearched))
        }
      })
    }
  }

  //SEND AWB FOR BREAKDOWN
  sendForBreakdown = async (awbs, status) => {
    let headers = {
      user_id: this.state.user_id,
      station: this.state.station,
      page: PAGE.BREAKDOWN,
      role: this.state.role,
      page_title: PAGE.breakdown_control,
    }
    let formdata = new FormData()
    formdata.append('status', status)

    awbs.map(async(awb) => {
      await Http.put(`/awb/${parseInt(awb.id)}`, formdata, { headers })
        .then(async (data) => {
          if (data && data?.status === 200) {
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data?.data?.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
          this.setState({ loading: false })
        }).catch(() => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
          this.setState({
            loading: false,
            servererror : true
          })
        })
    })
  }

  render() {
    // const changeConfirm = ({ id }) => {
    //   this.doneCheck(id, 'ULD')
    // }

    const showConfirm = (awb) => {
      awb.check_breakdown = true
    }

    let { workAreaData, servererror, loading } = this.state

    const awb_columns = [
      {
        title: '',
        dataIndex: 'a',
        key: 'a',
        align: 'left',
        width: '20%',
        className: 'space-nowrap',
        render: (_, record) => (
          <span>{record.dontshowflt? '' : record?.flights_in?.flight_no ? record?.flights_in?.flight_no: '' } </span>
        )
      },
      {
        title: '',
        dataIndex: 'awb_no',
        key: 'awb_no',
        className: 'space-nowrap',
        // width: '10%',
        render: (_, record) => (
          <span>{record?.awb_no ? formatAWBNo(record.awb_no) : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: 'b',
        key: 'b',
        width: '20%',
        render: () => <span></span>
      },
      {
        title: '',
        dataIndex: 'c',
        key: 'c',
        width: '25%',
        render: (_, record) => (
          <span className={record?.show_handling_time_after_tta === true ? record.amber_flag ? 'countdown amber' : record.handling_time_color === 'green' ? 'countdown green' : 'countdown red' : 'countdown red'}>
            {record?.show_handling_time_after_tta === true ?
              <>
                <Countdown className='hidden-countdown' onFinish={() => this.createCountdownPromise(record, 'AWB', true)} value={record.amber_countdown} format='HH:mm' />
                <Countdown onFinish={() => this.createCountdownPromise(record, 'AWB', false)} value={record.handling_time_after_tta} format='HH:mm' />
              </>
              : record.handling_time_as_timestamp}
          </span>
        )
      },
      {
        title: '',
        dataIndex: 'remaining_pcs',
        key: 'remaining_pcs'
      },
      {
        title: '',
        dataIndex: 'dest',
        key: 'dest',
        width: '12%'
      },
      {
        title: '',
        dataIndex: 'd',
        key: 'd',
        width: '10%',
        render: () => <span></span>
      },
    ]
    const uld_columns = [
      {
        title: 'FLT',
        dataIndex: 'flight_no',
        key: 'flight_no',
        render: (_, record) => (
          <span>{!record.sameflt? '' : record?.flights_in?.flight_no ? record?.flights_in?.flight_no : record?.flt ? record?.flt : record?.type === 'CART' && record.org === this.state.station ? 'EXPORT' : '-'}</span>
        )
      },
      {
        title: 'AWB',
        dataIndex: '',
        key: 'uld_no',
        align:'left',
        className: 'space-nowrap hd-txt-center',
        render: (_, record) => (
          <span>{record?.type === 'ULD' || record?.type === 'CART' ? record?.uld_no : record?.type +' - '+ record.uld_no}</span>
        )
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location'
      },
      {
        title: 'Countdown',
        dataIndex: 'countdown',
        key: 'countdown',
        render: (_, record) => (
          <span className={record?.show_handling_time_after_tta === true ? record.amber_flag ? 'countdown amber' : record.handling_time_color === true ? 'green' : 'red' : 'red'}>
            {record?.show_handling_time_after_tta === true ?
              <>
                <Countdown className='hidden-countdown' onFinish={() => this.createCountdownPromise(record, 'ULD', true)} value={record.amber_countdown} format='HH:mm' />
                <Countdown onFinish={() => this.createCountdownPromise(record, 'ULD', false)} value={record.handling_time} format='HH:mm' />
              </>
              : record.handling_time_as_timestamp}
          </span>
        )
      },
      {
        title: 'Pcs',
        dataIndex: 'pcs',
        key: 'pcs'
      },
      {
        title: 'Dest',
        dataIndex: 'dest',
        key: 'dest',
        render: (_, record) => (
          <span>{record?.station ? record?.station : ''}</span>
        )
      },
      {
        title: 'Done',
        dataIndex: 'done',
        key: 'done',
        className: 'pr-1vw no-print',
        render: (_, record) => (
          <>{record?.check_breakdown ? 
            <><Button onClick={() => this.sendForBreakdown(record?.awbs_in, 'completed')} type='primary' className='warehouse-done warehouse-confirm bkd' 
            >Confirm</Button></>:
            <><Button onClick={() => showConfirm(record)} type='primary' className='warehouse-done bkd'>Done</Button></>}
          </>
        )
      }
    ]

    return (
      <div className='warehouse-screen warehouse-dashboard'>
        <div className='text-right f-spacebtw'>
          <div className='no-print'>
            {this.state.role?.toUpperCase() === 'WAREHOUSE TV' ?
              <div className='d-flex'>
                {
                  this.state.logintype && this.state.logintype === 'DashTV' ? null :
                    <div className='mr-1vw'>
                      <Button className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<ArrowLeftOutlined />} size='large' onClick={()=>window.location.href = '/landing-page'} />
                      <p className='btn-text'>Back</p>
                    </div>
                }
                <div>
                  <Button className='custom-icon-button warehouse-filter rotate-180' type='primary' shape='circle' icon={<LogoutOutlined />} size='large' onClick={this.handleRedirect} />
                  <p className='btn-text'>Signout</p>
                </div>
              </div>
              :
              <div>
                <Button className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<HomeOutlined />} size='large' onClick={this.handleRedirect} />
                <p className='btn-text'>Home</p>
              </div>
            }
          </div>
          <h1 className='dash-title mr-0 dash-loc'>{this.state.station}</h1>
        </div>
        <div className='clock-container'>
          <Text className='clock-text'>
            {this.state.clock}
          </Text>
        </div>
        <h1 className='text-center'><span className='dash-title'>Breakdown Dashboard</span></h1>
        {
          servererror ?
            <p className='dis-server'>Disconnected from the server, Please refresh the page.</p> : null
        }
        {loading && (<div id='spinner' className='spinner-cont'>
          <Spin />
        </div>)}
        <Row
          gutter={[
            {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
            },
            {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
            },
          ]}
        >
          <div className='w-100 f-spacebtw mb-1p5'>
            <div className='d-flex'>
              <div className='selected-filter mb-0 '>Selected Filters:
                {this.renderFilterBlock()}
              </div>
            </div>

            <div span={2} className='row-1 pr-0 ml-auto no-print'>
              <Space direction='horizontal' className='w-action-btn'>
                <Input.Search
                  placeholder='Search AWB'
                  onSearch={(e) => this.onSearch(e)}
                  onChange={(e) => this.setSearchValue(e)}
                  allowClear
                  className={'warehouse-search' + (this.state.searchSelect? ' search-w100' : ' search-w0')}
                />
                <Button className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<PrinterOutlined />} size='large' onClick={()=>window.print()} />
                <Button disabled={this.state.is_search} className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<FilterOutlined />} size='large' onClick={this.showDrawer} />
              </Space>

            </div>
            <div className='ml-2vw fv-middle'>
              <Spin spinning={this.state.spinnerForULDs || this.state.spinnerForEvents} indicator={antIcon} className='spin-font no-print' />
            </div>
          </div>
          <div className='w-100'>{this.state.exclusionList?.length > 0 && <p className='selected-filter'><b> Product Exclusion list</b>: {this.state.exclusionList?.toString()}</p>}</div>
          <div className='w-100'>{this.state.tempSelProductList?.length > 0 && <p className='selected-filter'><b> Product Codes list</b>: {this.state.tempSelProductList?.toString()}</p>}</div>
          <Drawer
            title=''
            placement='top'
            width={500}
            closable={false}
            open={this.state.visible}
            className='warehouse-drawer'
          >
            <Row>
              <Button className='close-btn ml-auto' onClick={this.onClose} size='large' type='link'>X</Button>
            </Row>
            <Row
              gutter={[
                {
                  xs: 0,
                  sm: 0,
                  md: 0,
                  lg: 0,
                },
                {
                  xs: 0,
                  sm: 0,
                  md: 0,
                  lg: 0,
                },
              ]}
              className='warehouse-screen'
            >
              <Col span={6} className='row-1'>
                <Form.Item label='Workarea'>
                  <Select
                    size='small'
                    placeholder='Select work area'
                    dropdownClassName='warehouse-select'
                    // onChange={(e) => this.setState({ workArea: e })}
                    onChange={(e) => this.setState({
                      loading: true,
                      workArea: e,
                      locationList: [],
                      locationType: '',
                      codeList: [],
                      prodType: [],
                      data: [],
                      displayData: [],
                      tempSelProductList: [],
                      tempSelLocationList: '',
                    }, () => this.saveFilters(true))}
                    value={this.state.workArea}
                  >
                    {
                      workAreaData && workAreaData.map((item) => (
                        <Option key={item.area_name} value={item.area_name}>{item.area_name}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>

              <Col span={6} className='row-1'>
                <Form.Item label='Type'>
                  <Select
                    size='small'
                    value={this.state.workAreaType}
                    placeholder='Select type'
                    dropdownClassName='warehouse-select'
                    onChange={(e) => this.setState({ workAreaType: e })}>
                    <Option value='transit'>Transit</Option>
                    <Option value='local'>Local</Option>
                    <Option value=''>All</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={10} className='space-nowrap ml-neg8'>
                <div className='tv-res'>
                  <label className='transparent'>-</label>
                  <Form.Item>
                    <Checkbox
                      onChange={(e) => this.setState({
                        filterChecked: e.target.checked,
                        filterValue: e.target.checked == false ? '' : this.state.filterValue,
                      })}
                      checked={this.state.filterChecked}
                    >
                      Show ULDs with
                      <InputNumber
                        className='small-input pl-2 uld-time'
                        disabled={!this.state.filterChecked}
                        onChange={(e) => this.setState({ filterValue: e })}
                        controls={false}
                        min={0}
                        value={this.state.filterValue}
                      />
                      <span className='pl-1'> mins or more</span>
                    </Checkbox>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} className='row-1'>
                <Form.Item label='Cargo'>
                  <Select
                    size='small'
                    value={this.state.cargoType}
                    placeholder='Select cargo'
                    dropdownClassName='warehouse-select'
                    onChange={(e) => this.setState({ cargoType: e })}
                  >
                    <Option value=''>All</Option>
                    <Option value='BLK'>BLK</Option>
                    <Option value='SLU'>SLU</Option>
                    <Option value='PTU'>PTU</Option>
                    <Option value='ULD'>ULD</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} className='row-1'>
                <Form.Item label='Product'>
                  <Select
                    mode='multiple'
                    size='small'
                    value={this.state.prodType || []}
                    placeholder='Select product'
                    dropdownClassName='warehouse-select'
                    onChange={(e) => this.setState({ prodType: e?.includes('RESET') ? [] : e })}
                  >
                    <Option className='blue' key={'RESET'} value={'RESET'}>{'RESET'}</Option>
                    {this.state.codeListDisplay?.map((item) => (
                      <Option key={item} value={item}>{item}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} className='row-1'>
                <Form.Item label='Location' className='loc-search'>
                  <Select
                    allowClear
                    showSearch
                    size='small'
                    value={this.state.locationType || []}
                    placeholder='Select location'
                    dropdownClassName='warehouse-select'
                    onChange={(e) => this.setState({ locationType: e && e !== undefined ? e : '' })}
                  >
                    {this.state.locationList?.map((item) => (
                      <Option key={item?.value} value={item?.value}>{item?.value}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} className=''>
                <Form.Item label='Exclusion List'>
                  <Select
                    placeholder='Select product'
                    dropdownClassName='warehouse-select'
                    mode='tags'
                    size='small'
                    onSearch={(e) => this.setState({ exclusionSearch: e?.toUpperCase() })}
                    onSelect={(e) => this.handleOnSelectExclusion(e)}
                    onChange={() => this.handleOnSelectExclusion('')}
                    onDeselect={(e) => this.popExclusion(e)}
                    tokenSeparators={[',',' ','\t']}
                    value={this.state.exclusionArray}
                    searchValue={this.state.exclusionSearch}
                  >
                    {
                      this.state.prodPriorityData?.map((item) => (
                        <Option key={item?.p_code} value={item?.p_code}>{item?.p_code}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={4} xl={4} className='row-1'>
                <Form.Item label='DOM/INTL'>
                  <Select
                    size='small'
                    placeholder='Select DOM/INTL'
                    popupClassName='warehouse-select'
                    onChange={(e) => this.setState({ is_dom: e })}
                    value={this.state.is_dom}
                  >
                    <Option value=''>All</Option>
                    <Option value='DOM'>DOM</Option> 
                    <Option value='INTL'>INTL</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='ml-auto'>
                <Space direction='horizontal'>
                  <Button type='primary' className='custom-button warehouse-button mr-2vw' onClick={this.clearFilters}>Clear filters</Button>
                  <Button className='custom-button warehouse-button' onClick={() => {
                    window.scrollTo({
                      top: 350,
                      behavior: 'smooth',
                    })
                    this.exclusionFilter(true)
                  }} type='primary'>Apply filters</Button>
                </Space>
              </Col>
            </Row>
          </Drawer>
          {/* <div className='clock-container'>
            <Text className='clock-text'>
              {this.state.clock}
            </Text>
          </div> */}
          <Col xs={24} sm={24} md={24} lg={24} className=''>
            {((this.state.data?.length === 0) || (this.state.tempSelfilterChecked && this.state.tempSelfilterValue && this.state.displayData?.length === 0)) && <Table
              className='components-table-demo-nested warehouse-table bkd-table'
              columns={uld_columns}
              expandable={{
                expandedRowRender: record => <Table className='warehouse-table nested-table bkd-nested-table' columns={awb_columns} dataSource={record?.awbs_in} pagination={false} />,
                defaultExpandAllRows: true,
                rowExpandable: (record) => record?.type !== 'BLK',
              }}
              dataSource={ this.state.is_search ? this.state.data : this.state.tempSelfilterChecked && this.state.tempSelfilterValue ? this.state.displayData : this.state.data}
              pagination={false}
            />}
            {((this.state.displayData?.length > 0 && ((this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters())) || (this.state.data?.length > 0 && ((!this.state.tempSelfilterChecked) || this.state.tempSelfilterChecked && !this.state.tempSelfilterValue))) && <Table
              className='components-table-demo-nested warehouse-table bkd-table'
              columns={uld_columns}
              expandable={{
                expandedRowRender: record => <Table className='warehouse-table nested-table bkd-nested-table' columns={awb_columns} dataSource={record?.awbs_in} pagination={false} />,
                defaultExpandAllRows: true,
                rowExpandable: (record) => record?.type !== 'BLK',
              }}
              dataSource={( this.state.is_search ? this.state.data : this.state.tempSelfilterChecked && this.state.tempSelfilterValue) || this.isOtherFilters() ? this.state.displayData : this.state.data}
              pagination={false}
            />}
          </Col>
        </Row>
      </div>
    )
  }
}

export default Warehouse
