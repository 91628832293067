export const title = 'Cargo Hub Control'

export const constants = {
  DeleteText: 'Are you sure to delete this record?',
  ResetUser: 'Are you sure to reset password for this user?',
  Dashboard: 'Dashboard',
  SERVER_CONNECTION_ERROR: 'Failed to connect to server',
  NO_DATA_ERROR: 'No Flights Available',
  NO_DATA_AVAILABLE: 'No Data Available'
}

export const systemParameterTabs = {
  fleet: 'fleet',
  recovery_action: 'recovery_action',
  rebooking_teams_sla: 'rebooking_teams_sla',
  dynamic_info: 'dynamic_info',
  breakdown_work_area_mapping: 'breakdown_work_area_mapping',
  product_buildup_priority: 'product_buildup_priority',
  handling_times: 'handling_times',
  grouping: 'grouping',
  buildup_area_mapping: 'buildup_area_mapping',
  uld_mapping: 'uld_mapping',
  haulout_area_mapping: 'haulout_area_mapping',
  dod_milestones: 'dod_milestones'
}

export const pages = {
  INBOUND: 'Inbound Flight Preparation',
  inbound_flight_prep: 'Inbound Flight Preparation',
  BREAKDOWN: 'Breakdown Control',
  breakdown_control: 'Breakdown Control',
  REBOOKING: 'Rebooking Queue',
  rebooking_queue: 'Rebooking Queue',
  BUILDUP: 'Build Up Control',
  buildup_control: 'Build Up Control',
  buildup_dashboard: 'Build Up Dashboard',
}

export const rebookingQueueTabs = {
  pending: 'Pending',
  completed: 'Completed',
  successful: 'Successful',
}

export const gatekeeperTabs = {
  processed: 'Last Processed Messages',
  search: 'Search Messages',
  performance: 'Performance info',
  audit_trail: 'Audit Trail',
}

export const changeLogTabs = {
  admin_logs: 'Admin Log',
  system_logs: 'System Log',
}

export const developerDomainTabs = {
  clear_data: 'Clear Data',
  unprocessed_msgs: 'Unprocessed Messages'
}

export const events = {
  FFM_OUT_RECEIVED: 'FFM',
  FFM_RECEIVED: 'FFM Received',
  FLIGHT_EVENT: 'Flight Event',
  AWB_UPDATED: 'AWB UPDATED',
  ULD_UPDATED: 'ULD UPDATE',
  BOOKING_MESSAGE: 'ECIBOOK',
  FSU: 'FSU',
  SEGMENT: 'SEGMENT',
  SYS_PARAMS: 'SYSTEM_PARAMETERS',
  RETURN: 'RETURN',
  CANCEL: 'CANCEL',
}

export const station =[
  {
    label: 'ATL',
    value: 'ATL'
  },
  {
    label: 'LAX',
    value: 'LAX'
  },
  {
    label: 'BOS',
    value: 'BOS'
  },
  {
    label: 'JFK',
    value: 'JFK'
  },
  {
    label: 'SEA',
    value: 'SEA'
  },
  {
    label: 'DTW',
    value: 'DTW'
  },
  {
    label: 'MSP',
    value: 'MSP'
  },
  {
    label: 'SLC',
    value: 'SLC'
  },
  {
    label: 'ORD',
    value: 'ORD'
  },
  {
    label: 'HNL',
    value: 'HNL'
  },
  {
    label: 'SFO',
    value: 'SFO'
  }
]
export const stationTime ={
  ATL: '-0500',
  LAX: '-0800',
  BOS: '-0500',
  JFK: '-0500',
  SEA: '-0800',
  DTW: '-0500',
  MSP: '-0600',
  SLC: '-0700',
  ORD: '-0600',
  HNL: '-1000',
  SFO: '-0800',
}

export const timezones = {
  ATL: 'America/New_York',
  LAX: 'America/Los_Angeles',
  BOS: 'America/New_York',
  JFK: 'America/New_York',
  SEA: 'America/Los_Angeles',
  DTW: 'America/New_York',
  MSP: 'America/Chicago',
  SLC: 'America/Denver',
  ORD: 'America/Chicago',
  HNL: 'Pacific/Honolulu',
  SFO: 'America/Los_Angeles',
}

export const timezone = [
  {
    value: 'ATL',
    label: 'ATL',
    zone: 'America/New_York',
    ext: 'EST',
  },
  {
    value: 'LAX',
    label: 'LAX',
    zone: 'America/Los_Angeles',
    ext: 'PST',
  },
  {
    value: 'BOS',
    label: 'BOS',
    zone: 'America/New_York',
    ext: 'EST',
  },
  {
    value: 'JFK',
    label: 'JFK',
    zone: 'America/New_York',
    ext: 'EST',
  },
  {
    value: 'SEA',
    label: 'SEA',
    zone: 'America/Los_Angeles',
    ext: 'PST',
  },
  {
    value: 'DTW',
    label: 'DTW',
    zone: 'America/New_York',
    ext: 'EST',
  },
  {
    value: 'MSP',
    label: 'MSP',
    zone: 'America/Chicago',
    ext: 'CST',
  },
  {
    value: 'SLC',
    label: 'SLC',
    zone: 'America/Denver',
    ext: 'MST',
  },
  {
    value: 'ORD',
    label: 'ORD',
    zone: 'America/Chicago',
    ext: 'CST',
  },
  {
    value: 'HNL',
    label: 'HNL',
    zone: 'Pacific/Honolulu',
    ext: 'HST',
  },
  {
    value: 'SFO',
    label: 'SFO',
    zone: 'America/Los_Angeles',
    ext: 'PST',
  },
]

export const department =[
  {
    label: 'Dept1',
    value: 'Dept1'
  },
  {
    label: 'Dept2',
    value: 'Dept2'
  },
]

export const pagelist = [
  {
    key : '1',
    link : '/gatekeeper',
    title: 'Gatekeeper',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader'],
    is_menu: true
  },
  {
    key : '2',
    link : '/system-parameter',
    title: 'System Parameters',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader'],
    is_menu: true
  },
  {
    key : '3',
    link : '/notes',
    title: 'Notes',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'Re-booking Team', 'User'],
    is_menu: true
  },
  {
    key : '4',
    link : '/inbound-flight',
    title: 'Inbound Flight Preparation',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User'],
    is_menu: true
  },
  {
    key : '5',
    link : '/breakdown-control',
    title: 'Breakdown Control',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User'],
    is_menu: true
  },
  {
    key : '6',
    link : '/rebooking-queue',
    title: 'Rebooking Queue',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'Re-booking Team', 'User'],
    is_menu: true
  },
  {
    key : '7',
    link : '/manage-users',
    title: 'Manage Users',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader'],
    is_menu: true
  },
  {
    key : '8',
    link : '/dod-command-center',
    title: 'DoD Command Center',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'DOD Agent'],
    is_menu: true
  },
  {
    key : '9',
    link : '/buildup-control',
    title: 'Buildup Control',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User'],
    is_menu: true
  },
  {
    key : '10',
    link : '/breakdown-dashboard',
    title: 'Breakdown Dashboard',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User', 'Warehouse TV'],
    is_menu: true
  },
  {
    key : '11',
    link : '/recovery-dashboard',
    title: 'Recovery Dashboard',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User', 'Warehouse TV'],
    is_menu: true
  },
  {
    key : '12',
    link : '/buildup-dashboard',
    title: 'Buildup Dashboard',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User', 'Warehouse TV'],
    is_menu: true
  },
  {
    key : '13',
    link : '/haulout-dashboard',
    title: 'Haulout Dashboard',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User', 'Warehouse TV'],
    is_menu: true
  },
  {
    key : '14',
    link : '/reports',
    title: 'Reports',
    className : 'sider-menu-item',
    roles: ['Super Admin', 'Station Leader', 'User'],
    is_menu: true
  },
]

export const errormsg = { sel_work_area: 'Select Work Area' }

export const jsonTheme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#0000',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
}

export const changeLogLabels = {
  //FLEET
  ac_code: 'AC Code',
  aft_conf: 'Aft Hold Config',
  key: 'Aft Hold Config Key',
  name: 'Attachment',
  path: 'Aft Hold Config Path',
  alwd_uld_type: 'Allowed ULDs',
  cont_type: 'Cont Type',
  fwd_conf: 'Fwd Hold Config',
  gen_info: 'Gen Info',
  is_loose: 'Loose',
  is_uld: 'ULD',
  pax_cap: 'Pax Cap',
  //REBOOKING TEAMS & SLA
  q_priority: 'Queue Priority',
  rebook_team: 'Rebooking Team',
  sla_time: 'SLA',
  //RECOVERY ACTION
  action: 'Recovery Action',
  email: 'Email',
  is_breakdown: 'Breakdown',
  is_buildup: 'Buildup',
  is_gen_rebook: 'Gen Rebook',
  is_inbound: 'Inbound',
  is_notify: 'Notify',
  q_to: 'Queue to',
  station_limit: 'Limit to Station',
  recovery_teams_id: 'Recovery Teams ID',
  //DYNAMIC INFO
  ac_type: 'AC Type',
  attach_conf: 'Attachment',
  contry_code: 'Country Code',
  enddate: 'End Date',
  flightno: 'Flight #',
  prod_code: 'Prod Code',
  remarks: 'Remarks',
  spl_code: 'Special Codes',
  startdate: 'Start Date',
  uld_type: 'ULD Type',
  station: 'Station',
  //PWAM
  area_name: 'Work Area Name',
  area_id: 'Area ID',
  p_code: 'Product Code',
  transfer_type: 'Transfer Type',
  location: 'Location',
  //BUP
  priority: 'Priority',
  //HANDLING TIMES
  ac_load: 'AC LOAD',
  ac_offld: 'AC OFFLD',
  at_gate: 'AT GATE',
  bd_store: 'BD & STORE',
  build_up: 'BUILD UP',
  exp_acpt: 'ACCEPT',
  fpe: 'FPE',
  mvt: 'MVT',
  process: 'PROCESS',
  tfr_to_wh: 'TFR TO WH',
  trf_to_ac: 'TFR TO AC',
  indicator_time: 'Amber Indicator',
  //COMMON
  updatedAt: 'Updated At',
  //INBOUND
  uld_dispo: 'ULD Dispo',
  deliver_to: 'Deliver To',
  //REBOOKING
  reason: 'Reason',
  status: 'Status',
  rebook_req_datetime: 'Rebooking Requested',
  rebook_comp_datetime: 'Rebooking Completed',
  rebook_undo_datetime: 'Undo Rebooking',
}

export const cut_codes = [
  { 
    code: '11U Weight & Balance' ,
    reasons: [
      'Not Enough Payload Available',
      'Unable to Balance Aircraft',
      'Change in Flight Plan',
      'Other',
    ],
    cutby: 'ALA'
  },
  { 
    code: '12U Bulk Out' ,
    reasons: [
      'Needed Additional Positions for Bags',
      'Lost Space due to Positioning',
      'Unable to Fit within Bin Footprint',
      'Too Much Cargo Staged',
      'Other'
    ],
    cutby: 'ALA'
  },
  { 
    code: '13R ALA Failed to Load' ,
    reasons: [
      'Quick Turn/IROP',
      'No GSE Available',
      'No Staffing',
      'ALA Discretion',
      'Weather/Ramp Closed',
      'Overhang/Contour Issue',
      'Safety Concerns',
      'Other'
    ],
    cutby: 'ALA'
  },
  { 
    code: '15U Late/No Show at Gate' ,
    reasons: [
      'Late/No Show at Gate',
      'Other'
    ],
    cutby: 'ALA'
  },
  { 
    code: '16U Missing Label' ,
    reasons: [
      'Missing Labels ',
      'Other'
    ],
    cutby: 'ALA'
  },
  { 
    code: '17U Missing/Incorrect DG Info' ,
    reasons: [
      'Missing/Incorrect DG Info',
      'Other'
    ],
    cutby: 'ALA'
  },
  { 
    code: '18U Damaged FRT/ULD' ,
    reasons: [
      'Damaged FRT/ULD',
      'Other'
    ],
    cutby: 'ALA'
  },
  { 
    code: '19U Placard/RWS Discrepancy' ,
    reasons: [
      'Placard/RWS Discrepancy',
      'Other'
    ],
    cutby: 'ALA'
  },
  { 
    code: '20U Missing/Damaged Straps/Nets' ,
    reasons: [
      'Missing/Damaged Straps/Nets',
      'Other'
    ],
    cutby: 'ALA'
  },
  { 
    code: '21U Strap Point Inop' ,
    reasons: [
      'Strap Point Inop',
      'Other'
    ],
    cutby: 'ALA'
  },
  { 
    code: '36U Aircraft Mechanical' ,
    reasons: [
      'Aircraft Mechanical',
      'Other'
    ],
    cutby: 'ALA'
  },
  { 
    code: '51C Pilot Refusal' ,
    reasons: [
      'Pilot Refusal',
      'Other'
    ],
    cutby: 'ALA'
  },
  { 
    code: 'ALA Cut via ALIS' ,
    reasons: [
      'Not Enough Payload Available',
      'Unable to Balance Aircraft',
      'Needed Additional Positions for Bags',
      'Unable to Fit Within Bin Footprint',
      'Lost Space due to Positioning',
      'ALA Failed to Load',
      'No Show/Late to Gate',
      'Too Much Cargo Staged',
      'ULD Damaged',
      'Other'
    ],
    cutby: 'LCC'
  },
  { 
    code: 'FTP' ,
    reasons: [
      'Unable to Fit within Bin Footprint'
    ],
    cutby: 'LCC'
  },
  { 
    code: 'LCC Cut via ALIS' ,
    reasons: [
      'Manually Cut by LCC Agent'
    ],
    cutby: 'FDD'
  },
  { 
    code: 'LOF' ,
    reasons: [
      'Cargo Planned for Different Line of Flight'
    ],
    cutby: 'LCC'
  },
  { 
    code: 'MFP' ,
    reasons: [
      'Cargo Missed FPE'
    ],
    cutby: 'LCC'
  },
  { 
    code: 'OPN' ,
    reasons: [
      'Cargo Missed FPE'
    ],
    cutby: 'LCC'
  },
  { 
    code: 'SHP' ,
    reasons: [
      'Cargo Not Processed Correctly as SDH'
    ],
    cutby: 'LCC'
  },
  { 
    code: 'TOW' ,
    reasons: [
      'Planned Payload Exceeds Max Payload'
    ],
    cutby: 'LCC'
  },
  { 
    code: 'UTL' ,
    reasons: [
      'Unauthorized Shipment for this Aircraft'
    ],
    cutby: 'LCC'
  }, 
  { 
    code: 'WGT' ,
    reasons: [
      'Max Bin Weight or ULD Gross Weight Exceeded'
    ],
    cutby: 'LCC'
  },
  { 
    code: 'ZFW' ,
    reasons: [
      'Planned Payload Exceeds Max Payload'
    ],
    cutby: 'LCC'
  },
  {
    code: '34U Flight Cancelled',
    reasons: ['N/A'],
    cutby: 'N/A'
  }
]

export const audit_status =[
  {
    key: '1',
    audit_submitted: 'Yes',
    planned_accordingly: 'Yes',
    optimal: 'Yes',
    meet_wgt_goal: 'Yes',
    num_of_rides: 'zero',
    emote: 'SmileOutlined',
    color: 'green'
  },
  {
    key: '2',
    audit_submitted: 'Yes',
    planned_accordingly: 'Yes',
    optimal: 'Yes',
    meet_wgt_goal: 'Yes',
    num_of_rides: 'greater',
    emote: 'MehOutlined',
    color: 'green'
  },
  {
    key: '3',
    audit_submitted: 'Yes',
    planned_accordingly: 'Yes',
    optimal: 'Yes',
    meet_wgt_goal: 'No',
    num_of_rides: 'zero',
    emote: 'MehOutlined',
    color: 'green'
  },
  {
    key: '4',
    audit_submitted: 'Yes',
    planned_accordingly: 'Yes',
    optimal: 'Yes',
    meet_wgt_goal: 'No',
    num_of_rides: 'greater',
    emote: 'MehOutlined',
    color: 'green'
  },
  {
    key: '5',
    audit_submitted: 'Yes',
    planned_accordingly: 'Yes',
    optimal: 'No',
    meet_wgt_goal: 'Yes',
    num_of_rides: 'zero',
    emote: 'MehOutlined',
    color: 'green'
  },
  {
    key: '6',
    audit_submitted: 'Yes',
    planned_accordingly: 'Yes',
    optimal: 'No',
    meet_wgt_goal: 'Yes',
    num_of_rides: 'greater',
    emote: 'MehOutlined',
    color: 'green'
  },
  {
    key: '7',
    audit_submitted: 'Yes',
    planned_accordingly: 'Yes',
    optimal: 'No',
    meet_wgt_goal: 'No',
    num_of_rides: 'zero',
    emote: 'MehOutlined',
    color: 'green'
  },
  {
    key: '8',
    audit_submitted: 'Yes',
    planned_accordingly: 'Yes',
    optimal: 'No',
    meet_wgt_goal: 'No',
    num_of_rides: 'greater',
    emote: 'MehOutlined',
    color: 'green'
  },
  {
    key: '9',
    audit_submitted: 'Yes',
    planned_accordingly: 'No',
    optimal: 'Yes',
    meet_wgt_goal: 'Yes',
    num_of_rides: 'zero',
    emote: 'MehOutlined',
    color: 'green'
  },
  {
    key: '10',
    audit_submitted: 'Yes',
    planned_accordingly: 'No',
    optimal: 'Yes',
    meet_wgt_goal: 'Yes',
    num_of_rides: 'greater',
    emote: 'MehOutlined',
    color: 'green'
  },
  {
    key: '11',
    audit_submitted: 'Yes',
    planned_accordingly: 'No',
    optimal: 'Yes',
    meet_wgt_goal: 'No',
    num_of_rides: 'zero',
    emote: 'MehOutlined',
    color: 'green'
  },
  {
    key: '12',
    audit_submitted: 'Yes',
    planned_accordingly: 'No',
    optimal: 'Yes',
    meet_wgt_goal: 'No',
    num_of_rides: 'greater',
    emote: 'MehOutlined',
    color: 'green'
  },
  {
    key: '13',
    audit_submitted: 'Yes',
    planned_accordingly: 'No',
    optimal: 'No',
    meet_wgt_goal: 'Yes',
    num_of_rides: 'zero',
    emote: 'MehOutlined',
    color: 'green'
  },
  {
    key: '14',
    audit_submitted: 'Yes',
    planned_accordingly: 'No',
    optimal: 'No',
    meet_wgt_goal: 'Yes',
    num_of_rides: 'greater',
    emote: 'MehOutlined',
    color: 'green'
  },
  {
    key: '15',
    audit_submitted: 'Yes',
    planned_accordingly: 'No',
    optimal: 'No',
    meet_wgt_goal: 'No',
    num_of_rides: 'zero',
    emote: 'MehOutlined',
    color: 'green'
  },
  {
    key: '16',
    audit_submitted: 'Yes',
    planned_accordingly: 'No',
    optimal: 'No',
    meet_wgt_goal: 'No',
    num_of_rides: 'greater',
    emote: 'FrownOutlined',
    color: 'green'
  },
  {
    key: '17',
    audit_submitted: 'No',
    planned_accordingly: null,
    optimal: null,
    meet_wgt_goal: null,
    num_of_rides: 'zero',
    emote: 'MehOutlined',
    color: 'grey'
  },
  {
    key: '18',
    audit_submitted: 'No',
    planned_accordingly: null,
    optimal: null,
    meet_wgt_goal: null,
    num_of_rides: 'greater',
    emote: 'FrownOutlined',
    color: 'grey'
  },
]

export const ldd_status =[
  {
    key: '1',
    pallets_paired: 'Yes',
    pallets_paired_contact: null,
    conveyances_staged: 'Yes',
    conveyances_staged_contact: 'Yes',
    emote: 'SmileOutlined',
    color: 'green'
  },
  {
    key: '2',
    pallets_paired: 'Yes',
    pallets_paired_contact: null,
    conveyances_staged: 'Yes',
    conveyances_staged_contact: 'No',
    emote: 'FrownOutlined',
    color: 'green'
  },
  {
    key: '3',
    pallets_paired: 'Yes',
    pallets_paired_contact: null,
    conveyances_staged: 'No',
    conveyances_staged_contact: null,
    emote: 'MehOutlined',
    color: 'green'
  },
  {
    key: '4',
    pallets_paired: 'No',
    pallets_paired_contact: 'Yes',
    conveyances_staged: 'No',
    conveyances_staged_contact: null,
    emote: 'SmileOutlined',
    color: 'green'
  },
  {
    key: '5',
    pallets_paired: 'No',
    pallets_paired_contact: 'Yes',
    conveyances_staged: 'Yes',
    conveyances_staged_contact: 'No',
    emote: 'FrownOutlined',
    color: 'green'
  },
  {
    key: '6',
    pallets_paired: 'No',
    pallets_paired_contact: 'Yes',
    conveyances_staged: 'Yes',
    conveyances_staged_contact: 'Yes',
    emote: 'SmileOutlined',
    color: 'green'
  },
  {
    key: '7',
    pallets_paired: 'No',
    pallets_paired_contact: 'No',
    conveyances_staged: 'Yes',
    conveyances_staged_contact: 'Yes',
    emote: 'FrownOutlined',
    color: 'green'
  },
  {
    key: '8',
    pallets_paired: 'No',
    pallets_paired_contact: 'No',
    conveyances_staged: 'Yes',
    conveyances_staged_contact: 'No',
    emote: 'FrownOutlined',
    color: 'green'
  },
  {
    key: '9',
    pallets_paired: 'No',
    pallets_paired_contact: 'No',
    conveyances_staged: 'No',
    conveyances_staged_contact: null,
    emote: 'FrownOutlined',
    color: 'green'
  },
  {
    key: '10',
    pallets_paired: null,
    pallets_paired_contact: null,
    conveyances_staged: 'Yes',
    conveyances_staged_contact: null,
    emote: 'MehOutlined',
    color: 'red'
  },
  {
    key: '1',
    pallets_paired: null,
    pallets_paired_contact: null,
    conveyances_staged: null,
    conveyances_staged_contact: null,
    emote: 'MehOutlined',
    color: 'grey'
  },

]

export const ch_wt_goals = [
  {
    lane: 'MSPAMS',
    kg: 503,
    lbs: '1108.92517878993'
  },
  {
    lane: 'MSPCDG',
    kg: 482,
    lbs: '1062.62824488123'
  },
  {
    lane: 'MSPHND',
    kg: 132,
    lbs: '291.010224739257'
  },
  {
    lane: 'MSPICN',
    kg: 162,
    lbs: '357.148912179997'
  },
  {
    lane: 'MSPLHR',
    kg: 452,
    lbs: '996.489557440486'
  },
  {
    lane: 'MSPHNL',
    kg: 501,
    lbs: '1104.51608026036'
  },
  {
    lane: 'DTWAMS',
    kg: 501,
    lbs: '1104.51608026036'
  },
  {
    lane: 'DTWLHR',
    kg: 541,
    lbs: '1192.70099684802'
  },
  {
    lane: 'DTWCDG',
    kg: 467,
    lbs: '1029.55890116086'
  },
  {
    lane: 'DTWFCO',
    kg: 573,
    lbs: '1263.24893011814'
  },
  {
    lane: 'DTWFRA',
    kg: 515,
    lbs: '1135.38080106604'
  },
  {
    lane: 'DTWMUC',
    kg: 328,
    lbs: '723.116316018759'
  },
  {
    lane: 'DTWHND',
    kg: 413,
    lbs: '910.509263767523'
  },
  {
    lane: 'DTWICN',
    kg: 210,
    lbs: '462.970812085181'
  },
  {
    lane: 'DTWPVG',
    kg: 121,
    lbs: '266.759372677652'
  },
  {
    lane: 'DTWATL',
    kg: 519,
    lbs: '1144.19929272481'
  },
  {
    lane: 'DTWHNL',
    kg: 303,
    lbs: '668.000743151476'
  },
  {
    lane: 'SEAAMS',
    kg: 456,
    lbs: '1005.30804909925'
  },
  {
    lane: 'SEACDG',
    kg: 336,
    lbs: '740.75329933629'
  },
  {
    lane: 'SEALHR',
    kg: 317,
    lbs: '698.865463957155'
  },
  {
    lane: 'SEAHND',
    kg: 259,
    lbs: '570.997334905057'
  },
  {
    lane: 'SEAICN',
    kg: 156,
    lbs: '343.921174691849'
  },
  {
    lane: 'SEAPVG',
    kg: 198,
    lbs: '436.515337108885'
  },
  {
    lane: 'BOSAMS',
    kg: 493,
    lbs: '1086.87909694283'
  },
  {
    lane: 'BOSCDG',
    kg: 432,
    lbs: '952.397099146659'
  },
  {
    lane: 'BOSLHR',
    kg: 322,
    lbs: '709.888578530612'
  },
  {
    lane: 'BOSFCO',
    kg: 76,
    lbs: '167.551341516542'
  },
  {
    lane: 'BOSTLV',
    kg: 161,
    lbs: '354.944289265306'
  },
  {
    lane: 'BOSDUB',
    kg: 400,
    lbs: '881.849165876536'
  },
  {
    lane: 'BOSEDI',
    kg: 8,
    lbs: '17.6369833175307'
  },
  {
    lane: 'BOSLIS',
    kg: 71,
    lbs: '156.528226943085'
  },
  {
    lane: 'BOSATH',
    kg: 61,
    lbs: '134.481997796172'
  },
  {
    lane: 'LAXCDG',
    kg: 411,
    lbs: '906.100017938141'
  },
  {
    lane: 'LAXLHR',
    kg: 417,
    lbs: '919.327755426289'
  },
  {
    lane: 'LAXHND',
    kg: 530,
    lbs: '1168.45014478641'
  },
  {
    lane: 'LAXSYD',
    kg: 367,
    lbs: '809.096609691722'
  },
  {
    lane: 'LAXATL',
    kg: 462,
    lbs: '1018.5357865874'
  },
  {
    lane: 'LAXJFK',
    kg: 457,
    lbs: '1007.51267201394'
  },
  {
    lane: 'LAXHNL',
    kg: 642,
    lbs: '1415.36791123184'
  }
]

export default constants