import React, { Component } from 'react'
import { Row, Col, Select, Form, Table, Button, InputNumber, Drawer, Space, notification, Statistic, Spin, Checkbox, Input, Typography } from 'antd'
import { FilterOutlined, PrinterOutlined, LogoutOutlined, ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons'
import { pages as PAGE, constants, timezone } from '../../constants/constants'
import { format_gate, cacheRetrieve } from '../../utils/common'
const { Countdown } = Statistic
import '../warehouse/index.css'
import './index.css'
import Http from '../../utils/http'
import Cookies from 'universal-cookie'
import moment from 'moment'
import { cloneDeep } from 'lodash'
const { Option } = Select
const { Text } = Typography
const timeFormat = 'HH:mm'
import { logout, pageHandler, getCookie, COOKIE } from '../../utils/cookie'

function getTimeDifferenceInMinutes (difference) {
  if(difference) {
    return moment().diff(difference, 'minutes')
  } else return 0
}
function formatAWBNo(awb) {
  let result = ''
  if(awb) {
    result = [awb.slice(0, 3), '-', awb.slice(3)].join('')
  }
  return result
}

class HauloutDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      weight_unit: 'KG',
      station: new Cookies().get('station'),
      user_id: new Cookies().get('id'),
      timezone: new Cookies().get('timezone'),
      role: new Cookies().get('role'),
      logintype: getCookie(COOKIE.LOGINTYPE),
      timezone_cty: timezone.find((item) => item.value === new Cookies().get('station'))?.zone || 'America/New_York',
      clock: '',
      intervalID: null,
      hauloutList: [],
      workAreaData: [],
      fleetData: [],
      cookieInterval: null,
      isListening: false,
      halloutFilter: null,
      searchSelect: false,
      searchValue: '',
      wasSearched: false,

      workArea: null,
      workAreaLocations: [],
      ac_type: null,
      acTypeList: [],
      prod_code: [],
      prodCodeList: [],
      orgdest: null,
      orgDestList: [],
      location: null,
      locationList: [],
      hrs_to_fpe: '',

      tempWorkArea: null,
      temp_ac_type: null,
      wbCheck: false,
      nbCheck: false,
      rfsCheck: false,
      prod_codes: [],
      temp_prod_code: [],
      temp_location: null,
      temp_orgdest: null,
      tempWbCheck: false,
      tempNbCheck: false,
      tempRfsCheck: false,
      tempHrs_to_fpe: '',

      only_booked: false,
      red_locations: [],
      amber_locations: [],
      amber_value: 0,
      fpe_value: 0,
      expanded: [],

      hiddenInterval: null,
      hiddenMoment: null,
      is_clearfilters: false,
    }
    this._queue = []
    this._isBusy = false
  }
  //clock added to dashboard 
  clockTimerLogic = () => {
    this.setState({ clock: moment().tz(this.state.timezone_cty).format('DD MMM YYYY | hh:mm A') })
  }
  
  clockTimerInterval = () => {
    let clockInterval = setInterval(() => {
      this.clockTimerLogic()
    }, 60000)
    this.setState({ intervalID: clockInterval })
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search)
    const workArea = queryParams.get('workArea')
    const hrsFpe = queryParams.get('hrsFpe')
    const wbCheck = queryParams.get('wbCheck')
    const nbCheck = queryParams.get('nbCheck')
    const rfsCheck = queryParams.get('rfsCheck')

    this.setState({ clock: moment().tz(this.state.timezone_cty).format('DD MMM YYYY | hh:mm A') }, () => {
      this.clockTimerLogic()
      this.clockTimerInterval()
    })

    pageHandler(window.location.pathname, getCookie(COOKIE.ROLE))
    this.setState({ cookieInterval: setInterval(this.checkInterval, 1000) })

    setInterval(() => this.setState({ hiddenInterval: this.handlePageVisibility() }), 10000)
    // this.fetchWorkAreas()
    this.setState({
      loading: true,
      hrs_to_fpe: hrsFpe,
      workArea: workArea,
      wbCheck: wbCheck === 'true' ? true : false,
      nbCheck: nbCheck === 'true' ? true : false,
      rfsCheck: rfsCheck === 'true' ? true : false,

      tempHrs_to_fpe: hrsFpe,
      tempWorkArea: workArea,
      tempWbCheck: wbCheck === 'true' ? true : false,
      tempNbCheck: nbCheck === 'true' ? true : false,
      tempRfsCheck: rfsCheck === 'true' ? true : false,
    }, () => this.fetchWorkAreas())

    const hallout_events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=ho&stn=${this.state.station}`)
    hallout_events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
        if(!this.state.wasSearched){
          this.enqueue(myFakeAsyncJob)
        }
      }
    }
  }

  handlePageVisibility = () => {
    //HANDLE TAB VISIBILITY
    if(this.state.hiddenMoment === null && window.document.visibilityState === 'hidden') {
      this.setState({ hiddenMoment: moment().format('YYYY-MM-DD HH:mm:ss') })
    } else if(window.document.visibilityState === 'visible' && (this.state.hiddenMoment && getTimeDifferenceInMinutes(this.state.hiddenMoment) < 5)) {
      this.setState({ hiddenMoment: null })
    }
    if((this.state.hiddenMoment && (getTimeDifferenceInMinutes(this.state.hiddenMoment) > 5)) && this._queue?.length > 80) {
      this.refreshPage()
    }
  }

  refreshPage = () => {
    let url = 'haulout-dashboard?'
    if(this.state.workArea){
      url = url + `workArea=${this.state.workArea}`
    }
    if(this.state.hrs_to_fpe){
      url = url + `&hrsFpe=${this.state.hrs_to_fpe}`
    }
    if(this.state.wbCheck){
      url = url + `&wbCheck=${this.state.wbCheck}`
    }
    if(this.state.nbCheck){
      url = url + `&nbCheck=${this.state.nbCheck}`
    }
    if(this.state.rfsCheck){
      url = url + `&rfsCheck=${this.state.rfsCheck}`
    }
    window.location.href = url
  }

  /*************************************************************************************************/

  enqueue = (job) => {
    // we'll wrap the job in a promise and include the resolve and reject functions in the job we'll enqueue, so we can control when we resolve and execute them sequentially
    new Promise((resolve, reject) => {
      this._queue.push({
        job,
        resolve,
        reject
      })
    })
    // we'll add a nextJob function and call it when we enqueue a new job; we'll use _isBusy to make sure we're executing the next job sequentially
    this.nextJob()
  }
  nextJob = () => {
    if (this._isBusy) return
    const next = this._queue.shift()
    // if the array is empty shift() will return undefined
    if (next) {
      this.setState({ spinnerForEvents: true })
      this._isBusy = true
      next
        .job()
        .then((value) => {
          if(!this.state.wasSearched){
            this.eventList(value)
          }
          next.resolve(value)
          this._isBusy = false
          this.nextJob()
        })
        .catch((error) => {
          this.setState({ spinnerForEvents: false })
          next.reject(error)
          this._isBusy = false
          this.nextJob()
        })
    } else {
      this.setState({ spinnerForEvents: false })
    }
  }
  getFakeAsyncJob = (item) => {
    return () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(item)
        }, 600)
      })
  }
  eventList = (parsedData) => {
    if(parsedData?.TYPE === 'ULD_UPDATE' && (parsedData?.msg === 'FSU' || parsedData?.msg === 'status' || parsedData?.msg === 'SLU')) {
      this.updateULDOnFSUEvent(parsedData?.DETAILS, parsedData?.DETAILS?.haul_out)
    }
    else if(parsedData?.TYPE === 'FLIGHT_EVENT') {
      this.updateULDOnFlightEvent(parsedData?.DETAILS)
    }
    else if(parsedData?.TYPE === 'STATION_DETAILS') {
      this.fetchAmberValue()
    }
    else if(parsedData?.TYPE === 'COUNTDOWN') {
      this.changeFPEColor(parsedData?.DETAILS)
    }
    else if(parsedData?.TYPE === 'ULD_HAUL') {
      this.updateULDOnHauloutEvent(parsedData?.DETAILS, parsedData?.DETAILS?.haul_out)
    }
    else if(parsedData?.TYPE === 'ULD_HIDE') {
      this.updateULDOnHideEvent(parsedData?.DETAILS, parsedData?.DETAILS?.haul_out)
    }
    else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.Msg === 'FFM') {
      this.updateULDOnFFM(parsedData?.DETAILS)
    }
  }

  /*************************************************************************************************/

  checkInterval = () => {
    let newCookie = new Cookies().get('weight')
    if(newCookie !== this.state.weight_unit) {
      this.setState({ weight_unit: newCookie })
    }
  }
  updateULDOnFSUEvent = async (DETAILS, WORK_AREA) => {
    if(DETAILS?.uld_id) {
      let data = cloneDeep(this.state.hauloutList)
      if(this.state.workArea?.toUpperCase() === WORK_AREA?.toUpperCase() || this.state.workArea?.toUpperCase() === 'ALL' ) {
        let uld_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.uld_id))
        if(uld_index > -1) {
          if(DETAILS?.status?.toUpperCase() === 'COMPLETED') {
            data.splice(uld_index, 1)
            this.setState({ hauloutList: [], }, () => this.setState({ hauloutList: data }))
          } else {
            this.fetchIndividualFlightByULDId(parseInt(DETAILS?.uld_id)).then((item) => {
              if(!this.state.wasSearched){
                let uld_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.uld_id))
                if(item) {
                  if(uld_index > -1) {
                    data[uld_index] = this.processIndividualRow(item)
                    this.setState({ hauloutList: [], }, () => this.setState({ hauloutList: data }))
                  }
                }
              }
            })
          }
        } else {
          this.fetchIndividualFlightByULDId(parseInt(DETAILS?.uld_id)).then((item) => {
            if(!this.state.wasSearched){
              if(item) {
                data.push(this.processIndividualRow(item))
                this.setState({ hauloutList: [], }, () => this.setState({ hauloutList: data }))
              }
            }
          })
        }

      }
    }
  }
  updateULDOnFlightEvent = async (DETAILS) => {
    let data = cloneDeep(this.state.hauloutList)
    //FILTER ULDS BELONGING TO FLIGHT ID
    let shouldUpdate = false
    if(DETAILS?.flight?.latest_dept_date && (moment.utc().valueOf() < moment.utc(DETAILS?.flight?.latest_dept_date).valueOf())) {
      shouldUpdate = true
    } else if(DETAILS?.flight?.dept_date && (moment.utc().valueOf() < moment.utc(DETAILS?.flight?.dept_date).valueOf())) {
      shouldUpdate = true
    }
    if(shouldUpdate) {
      this.fetchULDsByFlightId(parseInt(DETAILS?.flight_id)).then((ulds) => {
        data = cloneDeep(this.state.hauloutList)
        if(!this.state.wasSearched){
          if(ulds && ulds?.length > 0) {
            ulds.map((uld) => {
              let index = data?.findIndex((p) => p.id === uld?.id)

              if(index > -1) {
                data?.splice(index, 1)
                data[index] = uld
              } else {
                data.push(uld)
              }
            })
            this.setState({ hauloutList: [], }, () => this.setState({ hauloutList: data }))
          }
        }
      })
    }
  }
  updateULDOnHauloutEvent = async (DETAILS, WORK_AREA) => {
    if(DETAILS?.uld_id) {
      let data = cloneDeep(this.state.hauloutList)
      if(this.state.workArea?.toUpperCase() === WORK_AREA?.toUpperCase() || this.state.workArea?.toUpperCase() === 'ALL' ) {
        let uld_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.uld_id))
        if(uld_index > -1) {
          this.fetchIndividualFlightByULDId(parseInt(DETAILS?.uld_id)).then((item) => {
            if(!this.state.wasSearched){
              let uld_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.uld_id))
              if(item) {
                if(uld_index > -1) {
                  data[uld_index] = this.processIndividualRow(item)
                  this.setState({ hauloutList: [], }, () => this.setState({ hauloutList: data }))
                }
              }
            }
          })
        } else {
          this.fetchIndividualFlightByULDId(parseInt(DETAILS?.uld_id)).then((item) => {
            if(!this.state.wasSearched){
              if(item) {
                data.push(this.processIndividualRow(item))
                this.setState({ hauloutList: [], }, () => this.setState({ hauloutList: data }))
              }
            }
          })
        }
      }
    }
  }
  updateULDOnHideEvent=  async (DETAILS, WORK_AREA) => {
    if(DETAILS?.uld_id) {
      let data = cloneDeep(this.state.hauloutList)
      if(this.state.workArea?.toUpperCase() === WORK_AREA?.toUpperCase() || this.state.workArea?.toUpperCase() === 'ALL' ) {
        let uld_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.uld_id))
        if(uld_index > -1) {
          if(DETAILS?.hide){
            data.splice(uld_index, 1)
            this.setState({ hauloutList: [], }, () => this.setState({ hauloutList: data }))
          }
        }
      }
    }
  }

  updateULDOnFFM =  async (DETAILS) => {
    if(DETAILS?.uld_id && DETAILS?.awb_id && DETAILS?.dept === true) {
      let data = cloneDeep(this.state.hauloutList)
      let uld_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.uld_id))
      if(uld_index > -1) {
        let awb_index = data[uld_index]?.awbs_in?.findIndex((p) => p?.awbs_id === parseInt(DETAILS?.awb_id))
        if(awb_index > -1 && DETAILS?.dept === true) {
          data[uld_index]?.awbs_in.splice(awb_index, 1)
          if(data[uld_index]?.awbs_in.length === 0){
            data.splice(uld_index, 1)
          }
          this.setState({ hauloutList: [], }, () => this.setState({ hauloutList: data }))
        }
      }
    }
  }

  /*************************************************************************************************/

  //FETCH INDIVIDUAL FLIGHT BY AWB NO
  fetchIndividualFlightByULDId = async (ID) => {
    let headers = {
      station: this.state.station,
      page: PAGE.BUILDUP
    }
    let obj = null
    let url = `hallout_list?id=${ID}`
    if(this.state.workArea && this.state.workArea !== 'ALL'){
      url= url+`&work_area=${this.state.workArea}`
    }
    await Http.get(url, { headers }).then(resp => {
      if (resp?.status === 200) {
        if (resp?.data?.data?.length > 0) {
          obj = resp?.data?.data[0]
        }
      }
    })
    return obj
  }
  //FETCH INDIVIDUAL FLIGHT BY AWB NO
  fetchULDsByFlightId = async (ID) => {
    let headers = {
      station: this.state.station,
      page: PAGE.BUILDUP
    }
    let obj = null
    let url = `hallout_list?flight_id=${ID}`
    if(this.state.workArea && this.state.workArea !== 'ALL'){
      url= url+`&work_area=${this.state.workArea}`
    }
    await Http.get(url, { headers }).then(resp => {
      if (resp?.status === 200) {
        if (resp?.data?.data?.length > 0) {
          obj = resp?.data?.data?.map((item) => { return { ...this.processIndividualRow(item) } })
        }
      }
    })
    return obj
  }
  //FETCH USER FILTERS
  fetchFilters = async (flag) => {
    await Http.get(`/page_filter/${this.state.user_id}`).then(async (data) => {
      if (data && data?.status === 200) {
        let res_data = data?.data?.data?.page_filters?.haulout_filter && data?.data?.data?.page_filters?.haulout_filter[this.state.station]
        if(res_data) {
          this.setState({
            halloutFilter: data?.data?.data?.page_filters?.haulout_filter,
            workArea: res_data?.workArea || this.state.workArea,
            //ac_type: res_data?.ac_type || this.state.temp_ac_type,
            wbCheck: res_data?.wbCheck || this.state.wbCheck,
            nbCheck: res_data?.nbCheck || this.state.nbCheck,
            rfsCheck: res_data?.rfsCheck || this.state.rfsCheck,
            prod_codes: res_data?.prod_codes || this.state.prod_codes,
            location: res_data?.location || this.state.temp_location,
            orgdest: res_data?.orgdest  || this.state.temp_orgdest,
            hrs_to_fpe: res_data?.hrs_to_fpe || this.state.hrs_to_fpe,
            tempWorkArea: res_data?.workArea || this.state.workArea,
            //tempAc_type: res_data?.ac_type || this.state.temp_ac_type,
            temp_prod_code: res_data?.prod_codes || this.state.prod_codes,
            temp_location: res_data?.location || this.state.temp_location,
            temp_orgdest: res_data?.orgdest || this.state.temp_orgdest,
            tempWbCheck: res_data?.wbCheck || this.state.wbCheck,
            tempNbCheck: res_data?.nbCheck || this.state.nbCheck,
            tempRfsCheck: res_data?.rfsCheck || this.state.rfsCheck,
            tempHrs_to_fpe: res_data?.hrs_to_fpe || this.state.tempHrs_to_fpe,
          })
        } else {
          this.setState({
            halloutFilter: data?.data?.data?.page_filters?.haulout_filter || {},
            workArea: this.state.tempWorkArea,
          })
        }
        if(flag) {
          this.fetchHaulOut(false)
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: data?.data?.message,
        })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({ servererror : true })
    })
  }
  //FETCH WORK AREAS
  fetchWorkAreas = async () => {
    let callback = this.fetchWorkAreasFn
    let pageURL =  'work_areas'
    let headers = {
      page: PAGE.BUILDUP,
      station: this.state.station,
      module: 'haulout'
    }
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchWorkAreasFn = async(resp, event) => {
    if(!event) this.setState({ loading: true })
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if (respData && respData?.data?.length > 0) {
        let alltilesobj = {
          key: respData && respData?.data?.length,
          station: 'ATL',
          area_name: 'ALL',
          ids: [],
          locations: []
        }
        respData?.data.push(alltilesobj)
        respData?.data.sort(function (a, b) {
          if (a.area_name === 'ALL' || a.area_name === 'ALL') return -1
          if (a.area_name < b.area_name) return -1
          if (a.area_name > b.area_name) return 1
          return 0
        })
        let work_area = this.state.workArea ? this.state.workArea : respData?.data[0]?.area_name
        let locations = []
        respData?.data?.map((item) => locations.push({
          area_name: item?.area_name,
          locations: item?.locations
        }))
        this.setState({
          workAreaData: respData?.data,
          workArea: respData?.data[0]?.area_name,
          tempWorkArea: work_area,
          workAreaLocations: locations,
        }
        ,()=>this.fetchFleet()
        )
      } else {
        this.setState({ workAreaData: [] })
        this.setState({ loading: false })
      }
    }
  }
  //FETCH FLEET
  fetchFleet = async () => {
    let callback = this.fetchFleetFn
    let pageURL =  'flights'
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchFleetFn = async(resp, event) => {
    if(!event) this.setState({ loading: true })
    const respData = await resp.json()
    if (respData && respData?.data?.length > 0) {
      this.setState({ fleetData: respData?.data })
    }
    if(!event) this.fetchAmberValue()
    else {
      this.processData()
    }
  }
  //FETCH HANDLING DATA
  fetchAmberValue = async () => {
    let callback = this.fetchAmberValueFn
    let pageURL =  `station_details?station=${this.state.station}`
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchAmberValueFn = async(resp, event) => {
    let old_only_booked = cloneDeep(this.state.only_booked)
    const respData = await resp.json()
    if(respData?.data?.length>0) {
      let result = respData.data[0]
      if(old_only_booked !== result?.details?.onlybooked) {
        this.setState({ hauloutList: [] })
      }
      this.setState({
        only_booked: result?.details?.onlybooked_haulout, //boolean
        red_locations: result?.details?.red_locations_haulout?.length > 0 ? result?.details?.red_locations_haulout?.split(',') : [], //comma seperated string
        amber_locations: result?.details?.amber_locations_haulout?.length > 0 ? result?.details?.amber_locations_haulout?.split(',') : [], //comma seperated string
        amber_value: result?.indicator_time || 0, //build up amber value
        fpe_value: result?.indicator_time2 || 0, //build up fpe value
      })
    }
    if(!event) this.fetchFilters(true)
    else {
      this.processData()
    }
  }

  showDrawer = () => {
    this.setState({ visible: true, })
  }
  onClose = () => {
    this.setState({
      visible: false,
      workArea: this.state.tempWorkArea,
      hrs_to_fpe: this.state.tempHrs_to_fpe,
      prod_code: this.state.temp_prod_code,
      location: this.state.temp_location,
      orgdest: this.state.temp_orgdest,
      wbCheck: this.state.tempWbCheck,
      nbCheck: this.state.tempNbCheck,
      rfsCheck: this.state.tempRfsCheck,
    })
  }
  //PROCESS DATA ON EVENT
  processData = () => {
    let awb_array = []
    this.state.hauloutList?.map((item) => {
      let obj = this.processIndividualRow(item)
      if(obj) {
        awb_array.push(obj)
      }
    })
    this.setState({
      hauloutList: [],
      loading: false,
    }, () => this.setState({ hauloutList: awb_array }))
  }
  //FETCH WORK AREAS
  fetchHaulOut = async (is_search = false) => {
    this.setState({ loading: true })
    let headers = {
      page: PAGE.HAULOUT,
      station: this.state.station,
    }

    let url = 'hallout_list'
    if(this.state.workArea && this.state.workArea !== 'ALL'){
      url= url+`?work_area=${this.state.workArea}`
    }
    if(is_search ){
      this.setState({ wasSearched: true })
      url= `hallout_list?search=${this.state.searchValue}`
    } else {
      this.setState({ wasSearched: false })
    }
    await Http.get(url, { headers: headers }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let respdata = cloneDeep(resp.data.data)
          respdata = respdata.map((item) => { return { ...this.processIndividualRow(item) } })
          this.setState({
            hauloutList: respdata,
            loading: false
          }, () => {
            window.scrollTo({
              top: 500,
              behavior: 'smooth',
            })
          })
        } else {
          this.setState({
            hauloutList: [],
            loading: false
          })
        }
      } else if(resp?.data?.status === 'error') {
        this.fetchHaulOut(false)
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //PROCESS INDIVIDUAL ULD
  processIndividualRow = (uld) => {
    uld.type = this.checkULDType(uld)
    uld.wrong_gate = this.isWrongGateLocation(uld)
    if(uld.wrong_gate) {
      uld.wrong_gate_color = 'red-ho'
    }
    if(uld?.location/* && uld?.inbound*/) {
      uld.location2 = format_gate(uld?.location)
      this.pushLocation(uld.location2, uld.wrong_gate)
    } else {
      this.pushLocation(uld?.flights_out?.flight_no, uld.wrong_gate)
    }
    // if(uld?.flights_out && uld?.flights_out?.ac_type) {
    //   this.pushAcType(uld.flights_out?.ac_type)
    // }
    uld.body_type = this.state.fleetData?.find((item) => item?.details?.ac_code === uld?.flights_out?.ac_type)?.details?.ac_type || ''
    uld.moments_till_indicator_yellow = ''
    uld.moments_till_indicator_red = ''
    uld.dep_minus = ''
    uld.color = ''
    //FOR SORTING
    uld.flight_no = uld?.flights_out?.flight_no || ''
    if(uld.blk && !uld.uld_no.match(/^([C]{1}[0-9]{3,8})/gi) && !uld?.uld_no?.includes('#') &&uld?.awbs_in.length >0 && uld?.awbs_in[0]?.prod_code){
      this.pushProdCode(uld?.awbs_in[0]?.prod_code, uld.location2, uld.wrong_gate)
      uld.prod = uld?.awbs_in[0]?.prod_code
    } else {
      if(uld?.awbs_in && uld?.awbs_in.length>0){
        let arr = []
        uld?.awbs_in.map((awb) => {
          let index = arr?.findIndex((p) => p === awb?.prod_code)
          if(index === -1) {
            arr.push(awb?.prod_code)
          }
        })
        if(arr.length > 0){
          if(arr.length > 1){
            uld.prod_codes = cloneDeep(arr)
            uld.prod = 'MIX'
            arr.push('MIX')
          } else {
            uld.prod = arr[0]
          }
          let prodCodeList = this.state.prodCodeList
          arr.map((ar) => {
            let index = prodCodeList?.findIndex((p) => p === ar)
            if(index === -1) {
              prodCodeList.push(ar)
              
            }
          })
          prodCodeList.sort()
          this.setState({ prodCodeList: [] }, () => this.setState({ prodCodeList: prodCodeList }))
        }
      }
    }
    //uld.prod = uld.blk && !uld.uld_no.match(/^([C]{1}[0-9]{3,8})/gi) && !uld?.uld_no?.includes('#') &&uld?.awbs_in.length >0 && uld?.awbs_in[0]?.prod_code ? uld?.awbs_in[0]?.prod_code : null
    if(uld.flights_out){
      if(uld.flights_out?.dept) {
        if(uld.flights_out?.dept?.act) {
          uld.flights_out.dept_prefix = 'A'
          uld.flights_out.dept_time = moment.utc(uld.flights_out.dept.act).tz(this.state.timezone)
        }
        else if(uld.flights_out?.dept?.eta) {
          uld.flights_out.dept_prefix = 'E'
          uld.flights_out.dept_time = moment.utc(uld.flights_out.dept.eta).tz(this.state.timezone)
        }
        else if(uld.flights_out?.dept?.schdl) {
          uld.flights_out.dept_prefix = 'S'
          uld.flights_out.dept_time = moment.utc(uld.flights_out.dept.schdl).tz(this.state.timezone)
        } else if(uld.flights_out?.dept_date) {
          uld.flights_out.dept_time = moment.utc(uld.flights_out.dept_date).tz(this.state.timezone)
        } else {
          uld.flights_out.dept_time = moment(uld.flights_out.flight_date)
        }
      } else if(uld.flights_out?.dept_date) {
        uld.flights_out.dept_time = moment.utc(uld.flights_out.dept_date).tz(this.state.timezone)
      } else if(uld.flights_out?.flight_date){
        uld.flights_out.dept_time = moment(uld.flights_out.flight_date)
      }
      else {
        uld.flights_out.dept_time = null
      }
      if(uld.flights_out.dept_time){
        let diff = moment(uld.flights_out.dept_time).diff(moment(), 'minutes')
        let fpe_on_type_yellow = uld.body_type === 'WB' ? 120 : uld.body_type === 'NB' ? 90 : 0
        let fpe_on_type_red = uld.body_type === 'WB' ? 90 : uld.body_type === 'NB' ? 60 : 0
        uld.dep_minus = moment().add(diff, 'minutes').format('YYYY-MM-DD HH:mm:ss')
        if(diff < fpe_on_type_yellow && diff > fpe_on_type_red) {
          uld.moments_till_indicator_red = moment().add(moment(uld.dep_minus).diff(moment().add(fpe_on_type_red, 'minutes'), 'minutes'), 'minutes').format('YYYY-MM-DD HH:mm:ss')
          uld.color = 'countdown amber'
        } else if(diff < fpe_on_type_red){
          uld.color = 'red'
        } else {
          uld.moments_till_indicator_yellow = moment().add(moment(uld.dep_minus).diff(moment().add(fpe_on_type_yellow, 'minutes'), 'minutes'), 'minutes').format('YYYY-MM-DD HH:mm:ss')
          uld.color = ''
        }
      }
    }
    let locations = this.state.workAreaLocations?.find((item) => item?.area_name?.toUpperCase() === this.state.workArea?.toUpperCase())?.locations || []
    if(
      (moment(uld.dep_minus).diff(moment(), 'minutes') > 0
      && (uld?.wrong_gate === true) || (locations?.includes(uld?.location))
      || (((uld?.location?.startsWith('0') && uld?.wrong_gate === true) || (!uld?.location?.startsWith('0')
      && uld?.wrong_gate === false))
      && this.state.workArea?.toUpperCase() === 'ALL'))
    ){
      // this.pushOrgDest(uld?.awbs_in[0]?.org+'/'+uld?.awbs_in[0]?.dest, uld.location2, uld.wrong_gate)
      this.pushOrgDest(uld?.flights_out?.org_stn+'/'+uld?.flights_out?.dest_stn, uld.location2, uld.wrong_gate)
    }
    return uld
  }
  //CHECK FOR ULD TYPE
  checkULDType = (uld) => {
    let type = ''
    if(uld?.blk && (!uld?.uld_no?.startsWith('C') && !uld?.uld_no?.endsWith('#'))) {
      type = 'BLK'
    } else {
      if(uld?.info?.remark?.toUpperCase()?.includes('SLU')) {
        type = 'SLU'
      } else if(!uld?.info?.remark?.toUpperCase()?.includes('SLU') && uld?.uld_dispo?.toUpperCase() !== 'BREAKDOWN' && uld?.master) {
        type = 'PTU'
      } else if(uld?.uld_no?.startsWith('C') || uld?.uld_no?.endsWith('#')) {
        type = 'CART'
      }
    }
    return type
  }
  //CHECK CORRECT GATE LOCATION
  isWrongGateLocation = (uld) => {
    //ULD AT WRONG GATE LOGIC
    let flight_gate = ''
    let wrong_gate = false
    if(uld?.location?.startsWith('0')) {
      let temp_awb_gate = ''
      if(uld?.flights_out?.dept?.gate) {
        flight_gate = '0'+uld?.flights_out?.dept?.gate
        if(uld?.location?.length === 3 && uld?.location?.match(/^[0]{1}[a-zA-Z]{1}[0-9]{1}$/g)) {
          temp_awb_gate = uld?.location?.slice(1,2) + uld?.location?.slice(0,1) + uld?.location?.slice(2)
        } else if(uld?.location?.length === 4 && uld?.location?.match(/^[0]{1}[a-zA-Z]{1}[0-9]{2}$/g)) {
          temp_awb_gate = uld?.location?.slice(1)
        } else if(uld?.location?.length === 4 && uld?.location?.match(/^[0]{1}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}$/g)) {
          temp_awb_gate = uld?.location?.slice(1)
        }

        if(flight_gate?.length === 3 && flight_gate?.match(/^[0]{1}[a-zA-Z]{1}[0-9]{1}$/g)) {
          flight_gate = flight_gate?.slice(1,2) + flight_gate?.slice(0,1) + flight_gate?.slice(2)
        } else if(flight_gate?.length === 4 && flight_gate?.match(/^[0]{1}[a-zA-Z]{1}[0-9]{2}$/g)) {
          flight_gate = flight_gate?.slice(1)
        } else if(flight_gate?.length === 4 && flight_gate?.match(/^[0]{1}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}$/g)) {
          flight_gate = flight_gate?.slice(1)
        }

        if(temp_awb_gate !== flight_gate) {
          wrong_gate = true
        }
      }
    }
    return wrong_gate
  }
  //PUSH LOCATION FOR FILTER
  pushLocation = (location, is_wrong_gate) => {
    let locations = this.state.workAreaLocations?.find((item) => item?.area_name?.toUpperCase() === this.state.workArea?.toUpperCase())?.locations || []
    if(is_wrong_gate === true || (locations?.includes(location) || this.state.workArea?.toUpperCase() === 'ALL' )) {
      let workLocationList = this.state.locationList
      let index = workLocationList?.findIndex((p) => p.text === location)
      if(index === -1) {
        workLocationList.push({
          text: location,
          value: location,
        })
        this.sort(workLocationList, 'text')
        this.setState({ locationList: [] }, () => this.setState({ locationList: workLocationList }))
      }
    }
  }
  //PUSH AC TYPE FOR FILTER
  pushAcType = (type) => {
    let acList = this.state.acTypeList
    let index = acList?.findIndex((p) => p.text === type)
    if(index === -1) {
      acList.push({
        text: type,
        value: type,
      })
      this.setState({ acTypeList: [] }, () => this.setState({ acTypeList: acList }))
    }
  }
  //PUSH PROD CODE FOR FILTER
  pushProdCode = (code, location, is_wrong_gate) => {
    let locations = this.state.workAreaLocations?.find((item) => item?.area_name?.toUpperCase() === this.state.workArea?.toUpperCase())?.locations || []
    if(is_wrong_gate === true || (locations?.includes(location) || this.state.workArea?.toUpperCase() === 'ALL')) {
      let prodCodeList = this.state.prodCodeList
      let index = prodCodeList?.findIndex((p) => p === code)
      if(index === -1) {
        prodCodeList.push(code)
        prodCodeList.sort()
        this.setState({ prodCodeList: [] }, () => this.setState({ prodCodeList: prodCodeList }))
      }
    }
  }
  //PUSH ORG DEST FOR FILTER
  pushOrgDest = (code, location, is_wrong_gate) => {
    let locations = this.state.workAreaLocations?.find((item) => item?.area_name?.toUpperCase() === this.state.workArea?.toUpperCase())?.locations || []
    if(is_wrong_gate === true || (locations?.includes(location) || this.state.workArea?.toUpperCase() === 'ALL')) {
      let orgDestList = this.state.orgDestList
      let index = orgDestList?.findIndex((p) => p === code)
      if(index === -1) {
        orgDestList.push(code)
        orgDestList.sort()
        this.setState({ orgDestList: [] }, () => this.setState({ orgDestList: orgDestList }))
      }
    }
  }
  //GET ADDITIONAL DAYS
  getAdditionalDays = (flight) => {
    // if(moment.tz(flight.dept_time, this.state.timezone).diff(moment().tz(this.state.timezone), 'days') > 0) {
    //   return `+${moment.tz(flight.dept_time, this.state.timezone).diff(moment().tz(this.state.timezone), 'days')}`
    if(moment.tz(((flight.dept_time).format('YYYY-MM-DD')+' 00:00'), this.state.timezone).diff(moment(moment().format('YYYY-MM-DD')+' 00:00').tz(this.state.timezone), 'days') > 0) {
      return `+${moment.tz(((flight.dept_time).format('YYYY-MM-DD')+' 00:00'), this.state.timezone).diff(moment(moment().format('YYYY-MM-DD')+' 00:00').tz(this.state.timezone), 'days')}`
    } else {
      return ''
    }
  }
  //APPLY FILTER
  applyFilters = () => {
    let api_flag = this.isAPIFilters()
    let is_clearfilters = cloneDeep(this.state.is_clearfilters)
    //IF WORK AREA IS CHANGED, CLEAR THE QUEUE
    if(api_flag) {
      this._queue = []
    }
    this.setState({
      is_clearfilters: false,
      visible: false,
      tempHrs_to_fpe: this.state.hrs_to_fpe,
      tempWorkArea: this.state.workArea,
      temp_ac_type: this.state.ac_type,
      temp_location: this.state.location,
      temp_orgdest: this.state.orgdest,
      temp_prod_code: this.state.prod_codes,
      tempWbCheck: cloneDeep(this.state.wbCheck),
      tempNbCheck: cloneDeep(this.state.nbCheck),
      tempRfsCheck: cloneDeep(this.state.rfsCheck),
    }, async () => {
      if(is_clearfilters || api_flag || (this.isUIFilters() || (this.state.tempHrs_to_fpe !== this.state.hrs_to_fpe))) {
        let haulout_filter = cloneDeep(this.state.halloutFilter)
        haulout_filter[this.state.station] = { 
          hrs_to_fpe: this.state.hrs_to_fpe,
          workArea: this.state.workArea,
          //ac_type: res_data?.ac_type || this.state.temp_ac_type,
          wbCheck: this.state.wbCheck,
          nbCheck: this.state.nbCheck,
          rfsCheck: this.state.rfsCheck,
          prod_codes: this.state.prod_codes,
          location: this.state.temp_location,
          orgdest: this.state.temp_orgdest,
        }
        let formdata = { haulout_filter: haulout_filter }
        await Http.put(`/page_filter/${this.state.user_id}`, formdata).then(async (data) => {
          if (data && data?.status === 200) {
            if(api_flag) {
              this.resetPage()
            }
            this.fetchFilters(api_flag)
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
        }).catch(() => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
          this.setState({ servererror : true })
        })
      }
    })
  }
  //RESET
  resetPage = async () => {
    this.setState({
      visible: false,
      page: 1,
      hauloutList: [],
      expanded: [],
    })
  }
  //CLEAR FILTERS
  clearFilters = () => {
    this.setState({
      is_clearfilters: true,
      ac_type: null,
      orgdest: null,
      prod_code: [],
      location: null,
      hrs_to_fpe: '',
      temp_ac_type: null,
      wbCheck: false,
      nbCheck: false,
      rfsCheck: false,
      prod_codes: [],
      temp_prod_code: [],
      temp_location: null,
      temp_orgdest: null,
      tempWbCheck: false,
      tempNbCheck: false,
      tempRfsCheck: false,
      tempHrs_to_fpe: '',
    }, () => this.applyFilters())
  }
  //RETURN TRUE IF UI FILTERS ARE SELECTED
  isUIFilters = () => {
    return this.state.temp_ac_type ? true : false ||
    this.state.temp_location ? true : false ||
    this.state.temp_orgdest ? true : false ||
    this.state.temp_prod_code?.length>0 ? true : false ||
    this.state.tempWbCheck ? true : false ||
    this.state.tempNbCheck ? true : false ||
    this.state.tempRfsCheck ? true : false
  }
  //RETURN TRUE IF API FILTERS ARE SELECTED
  isAPIFilters = () => {
    return this.state.tempWorkArea !== this.state.workArea
  }
  //CHANGE ROW COLOR
  changeRowColor = (record) => {
    if(record?.wrong_gate) {
      return 'red-ho-bg'
    }
    // else {
    //   let red_filter = this.state.red_locations?.filter((loc1) => record?.location?.endsWith(loc1))
    //   let amber_filter = this.state.amber_locations?.filter((loc2) => record?.location?.endsWith(loc2))
    //   if(red_filter?.length > 0 || !record?.flights_out) {
    //     return 'red-ho-bg'
    //   }

    //   if(amber_filter?.length > 0) {
    //     return 'amber-ho-bg'
    //   }
    // }
  }
  //SORT
  sort = (array, type) => {
    if(type === 'dep_minus') {
      array && array.sort(function(a, b) {
        // Compare the fields
        if (moment(a?.dep_minus) < moment(b?.dep_minus)) return -1
        if (moment(a?.dep_minus) > moment(b?.dep_minus)) return 1
        return 0
      })
    } else {
      array && array.sort(function(a, b) {
        // Compare the fields
        return ('' + a[type]).localeCompare(b[type])
      })
    }
  }
  //CHANGE FPE COLOR
  changeFPEColor = (DETAILS) => {
    let data = cloneDeep(this.state.hauloutList)
    let uld_index = data?.findIndex((p) => p.id === DETAILS?.id)
    if(uld_index > -1) {
      data[uld_index].color = DETAILS?.color
      // data[uld_index] = this.processIndividualRow(data[uld_index])
      this.setState({ hauloutList: [] }, () => this.setState({ hauloutList: data }))
    }
  }
  //PARSE COUNTDOWN DATA AND SEND TO QUEUE
  createCountdownPromise = (record, color) => {
    if(moment(record?.dep_minus).add(5, 'seconds').valueOf() > moment().valueOf()) {
      let parsedData = {
        TYPE: 'COUNTDOWN',
        DETAILS: {
          id: record?.id,
          color: color,
        }
      }
      const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
      this.enqueue(myFakeAsyncJob)
    }
  }
  //REDIRECT LOGIC
  handleRedirect = () => {
    if(this.state.role?.toUpperCase() === 'WAREHOUSE TV') {
      logout()
    } else {
      pageHandler('', this.state.role)
    }
  }
  //HANDLE DONE
  done = (record) => {
    let data = cloneDeep(this.state.hauloutList)
    let awb_index = data?.findIndex((p) => p.id === record?.id)
    if(awb_index > -1) {
      data[awb_index].done = true
      this.setState({ hauloutList: [] }, () => this.setState({ hauloutList: data }))
    }
  }
  //HANDLE CONFIRM
  confirm = async (record) => {
    let headers = {
      station: this.state.station,
      page: PAGE.BUILDUP
    }
    let uld_id = record?.id
    if(uld_id){
      // loading = true
      let hauloutList = cloneDeep(this.state.hauloutList)
      let index = hauloutList?.findIndex((p) => p?.id === record.id)
      if(index > -1) {
        hauloutList[index].loading = true
        this.setState({ hauloutList: hauloutList })
      }

      await Http.get(`/hide_uld/${uld_id}`, { headers }).then(async (data) => {
        if (data && data?.status === 200) {
          //DO NOTHING
        } else {
          let hauloutList = cloneDeep(this.state.hauloutList)
          let index = hauloutList?.findIndex((p) => p?.id === record.id)
          if(index > -1) {
            hauloutList[index].loading = true
            this.setState({ hauloutList: hauloutList })
          }
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      }).catch(() => {
        let hauloutList = cloneDeep(this.state.hauloutList)
        let index = hauloutList?.findIndex((p) => p?.id === record.id)
        if(index > -1) {
          hauloutList[index].loading = true
          this.setState({ hauloutList: hauloutList })
        }
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({ servererror : true })
      })
    }
  }
  //RENDER FILTER SELECTION STRING
  renderFilterBlock = () => {
    let work_area = this.state.tempWorkArea ? false : true
    let hrs_to_fpe = this.state.tempHrs_to_fpe ? false : true
    let ac_type = this.state.temp_ac_type ? false : true
    let prod_codes = this.state.temp_prod_code?.length > 0 ? false : true
    let location = this.state.temp_location ? false : true
    let orgdest = this.state.temp_orgdest ? false : true
    let equipment = (!this.state.tempWbCheck && !this.state.tempNbCheck && !this.state.tempRfsCheck)
    if(this.state.wasSearched){
      return (
        <><br />
          <Row className='mt-1vw pl-2vw sel-flt-rw'>
            <Col xs={12}><b> Search: </b><span>{this.state.searchValue}</span></Col> <></>
          </Row>
        </>
      )
    }
    return (
      <><br />
        <Row className='mt-1vw pl-2vw sel-flt-rw'>
          { !work_area ? <Col xs={12}><b> Areas: </b><span>{this.state.tempWorkArea}</span></Col> : <></> }
          { !hrs_to_fpe ? <Col xs={12}><b> &lt; Hrs to Dep: </b><span>{this.state.tempHrs_to_fpe}</span></Col>: <></>}
          { !ac_type ? <Col xs={12}><b> A/C: </b><span>{this.state.temp_ac_type}</span></Col> : <></> }
          { !prod_codes ? <Col xs={12}><b> Products: </b><span>{this.state.temp_prod_code?.toString()}</span></Col> : <></> }
          { !location ? <Col xs={12}><b> Location: </b><span>{this.state.temp_location}</span></Col> : <></>}
          { !orgdest ? <Col xs={12}><b> Org/Dest: </b><span>{this.state.temp_orgdest}</span></Col> : <></>}
          { !equipment ? <Col xs={12}><b> Equipment: </b><span>{this.state.tempWbCheck ? 'WB' : ''} {this.state.tempNbCheck ? 'NB' : ''} {this.state.tempRfsCheck ? 'TRK' : ''}</span></Col> : <></> }
        </Row>
      </>
    )
  }
  //OTHER FILTERS
  otherFilters = (filtered_data) => {
    let filters = {}
    if(this.state.temp_ac_type) {
      filters.isAcType = item => item?.flights_out?.ac_type === this.state.temp_ac_type
    }
    if(this.state.temp_location) {
      filters.isLocation = item => item?.location === this.state.temp_location || (!item?.inbound && item?.flights_out?.flight_no === this.state.temp_location)
    }
    if(this.state.temp_orgdest) {
      // filters.isorgdest = item => this.state.temp_orgdest?.includes(item?.awbs_in[0]?.org+'/'+item?.awbs_in[0]?.dest)
      filters.isorgdest = item => this.state.temp_orgdest?.includes(item?.flights_out?.org_stn+'/'+item?.flights_out?.dest_stn)
    }
    // if(this.state.temp_prod_code?.length > 0) {
    //   filters.isProdCode = item => this.state.temp_prod_code?.includes(item?.prod)
    // }
    let data = filtered_data?.filter(item => Object.values(filters)?.every(f => f(item)))

    if(this.state.temp_prod_code?.length > 0) {
      let filter1 = data.filter(obj => this.state.temp_prod_code.some(value => value === obj.prod))
      let filter2 = data.filter(obj => this.state.temp_prod_code.some(value => obj.prod_codes?.includes(value)))
      data = filter1.concat(filter2).reduce((uniqueArray, obj) => {
        const found = uniqueArray.some(item => item.id === obj.id)
        if (!found) {
          uniqueArray.push(obj)
        }
        return uniqueArray
      }, [])
    }
    let wbfilter = []
    let nbfilter = []
    let rfsfilter = []
    if(this.state.tempWbCheck) {
      //filters.wbEquals = item => item?.body_type === 'WB'
      wbfilter = data.filter(item => item?.body_type === 'WB')
    }
    if(this.state.tempNbCheck) {
      //filters.nbEquals = item => item?.body_type === 'NB'
      nbfilter = data.filter(item => item?.body_type === 'NB')
    }
    if(this.state.tempRfsCheck) {
      //filters.rfsEquals = item => (item?.flights_out?.flight_no?.match(/^DL7[0-9]{3,5}[A-Z]{1}/g) || item?.body_type === 'TRK')
      rfsfilter = data.filter(item => (item?.flights_out?.flight_no?.match(/^DL7[0-9]{3,5}[A-Z]{1}/g) || item?.body_type === 'TRK'))
    }
    if( this.state.tempWbCheck || this.state.tempNbCheck || this.state.tempRfsCheck ){
      data = wbfilter.concat(nbfilter)
      data = data.concat(rfsfilter)
    }
    return data
    //return filtered_data?.filter(item => Object.values(filters)?.every(f => f(item)))
  }
  //RENDER DATA
  renderData = (data) => {
    let locations = this.state.workAreaLocations?.find((item) => item?.area_name?.toUpperCase() === this.state.workArea?.toUpperCase())?.locations || []
    let d = []
    if(this.state.tempHrs_to_fpe) {
      d = data?.filter((item) => moment(item.dep_minus).diff(moment(), 'hours') < this.state.tempHrs_to_fpe)
      if(d?.length > 0 && this.isUIFilters()) {
        d = this.otherFilters(d)
      }
    } else if(this.isUIFilters()) {
      d = this.otherFilters(data)
    } else {
      //FILTER BY NON MATCHING GATE
      // d = data?.filter((item) => item?.wrong_gate === true || locations?.includes(item?.location))
      d = data
    }
    d = d?.filter((item) => moment(item.dep_minus).diff(moment(), 'minutes') > 0 && (item?.wrong_gate === true) || (locations?.includes(item?.location)) || (((item?.location?.startsWith('0') && item?.wrong_gate === true) || (!item?.location?.startsWith('0') && item?.wrong_gate === false)) && this.state.workArea?.toUpperCase() === 'ALL'))
    if(this.state.wasSearched){
      d = data?.filter((item) => (item?.wrong_gate === true) || ((item?.location?.startsWith('0') && item?.wrong_gate === true) || (!item?.location?.startsWith('0') && item?.wrong_gate === false)))
    }
    this.sort(d, 'location')
    this.sort(d, 'flight_no')
    this.sort(d, 'dep_minus')
    return d
  }

  // AWB SEARCH
  setSearchValue (e){
    this.setState({ searchValue: e.target.value },()=> {
      if(this.state.wasSearched && !this.state.searchValue){
        this.fetchHaulOut(false)
      }
    })
  }
  onSearch = (e ) => {
    let old_searchValue = cloneDeep(this.state.searchValue)
    let fetch_flag = false
    if(old_searchValue.trim() !== e.trim())
      fetch_flag = true
    if(!e){
      this.setState({
        searchSelect: !this.state.searchSelect,
        searchValue: e
      },()=>{
        if(fetch_flag)this.fetchHaulOut(false)
      })
    }

    if(e){
      //hallout_list
      this.fetchHaulOut(true)
    }
  }

  render() {
    const uld_columns = [
      {
        title: 'FLT',
        dataIndex: 'flt',
        key: 'flt',
        align:'left',
        render: (_, record) => (
          <span>{ `${record?.flights_out?.flight_no} ${this.getAdditionalDays(record?.flights_out? record?.flights_out : null)}`}</span>
        )
      },
      {
        title: 'A/C',
        dataIndex: 'ac',
        key: 'ac',
        render: (_, record) => (
          <span>{record?.flights_out?.ac_type ? `${record.flights_out.ac_type}` : 'N/A'}</span>
        )
      },
      {
        title: 'Dest',
        dataIndex: 'dest',
        key: 'dest',
        render: (_, record) => (
          <span>{record?.flights_out?.dest_stn ? `${record.flights_out.dest_stn}` : 'N/A'}</span>
        )
      },
      {
        title: 'ULD/CRT/AWB',
        dataIndex: 'uld_awb',
        key: 'uld_awb',
        className:'space-nowrap',
        render: (_, record) => (
          <span>{record?.uld_no ? `${record.uld_no}` : 'N/A'}</span>
        )
      },
      {
        title: 'Prod',
        dataIndex: 'prod',
        key: 'prod',
        render: (_, record) => (
          <span>{record?.prod ? `${record.prod}` : '-'}</span>
        )
      },
      {
        title: 'LOCN',
        dataIndex: 'locn',
        key: 'locn',
        render: (_, record) => (
          <span className={record?.wrong_gate_color}>
            {record?.location2 ? `${record.location2}` : 'N/A'}
          </span>
        )
      },
      {
        title: 'Gate',
        dataIndex: 'gate',
        key: 'gate',
        render: (_, record) => (
          <span>
            {record?.flights_out?.dept?.gate ? `${record.flights_out.dept.gate}` : 'N/A'}
          </span>
        )
      },
      {
        title: 'Departure',
        dataIndex: 'latest_dep',
        key: 'latest_dep',
        className:'space-nowrap',
        render: (_, record) => (
          <span className={record?.wrong_gate ? 'red' : record?.color}>
            <Countdown className='hidden-countdown' onFinish={() => this.createCountdownPromise(record, 'countdown amber')} value={record?.moments_till_indicator_yellow} format='HH:mm:ss' />
            {record?.flights_out?.dept_time? `${record.flights_out.dept_time.format(timeFormat)}` : 'N/A'}
          </span>
        )
      },
      {
        title: 'Dep-',
        dataIndex: 'dep',
        key: 'dep',
        render: (_, record) => (
          <span className={record?.wrong_gate ? 'red' : record?.color}>
            <Countdown className='hidden-countdown' onFinish={() => this.createCountdownPromise(record, 'red')} value={record?.moments_till_indicator_red} format='HH:mm:ss' />
            <Countdown className='countdown' value={record?.dep_minus} format='HH:mm' />
          </span>
        )
      },
      {
        title: 'Done',
        dataIndex: 'done',
        key: 'done',
        className: 'no-print',
        render: (_, record) => (
          <>
            {
              !record?.done ?
                <><Button onClick={() => this.done(record)} type='primary' className='warehouse-done'>Done</Button></> :
                <><Button onClick={() => this.confirm(record)} type='primary' className='warehouse-done warehouse-confirm'>Confirm</Button>
                </>
            }
          </>
        )
      }
    ]
    const awb_columns = [
      {
        title: '',
        dataIndex: '',
        key: '',
        align:'',
        className: 'w-16vw',
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        width: '10%',
      },
      {
        title: '',
        dataIndex: 'awb_no',
        key: 'awb_no',
        className: 'w-16vw',
        render: (_, record) => (
          <span className='nested-awb'>{record?.awb_no ? formatAWBNo(record.awb_no) : 'N/A'}</span>
        ),
      },
      {
        title: '',
        dataIndex: 'product',
        key: 'product',
        render: (_, record) => (
          <span className='nested-prod'>{record?.prod_code ? record?.prod_code : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: 'arrival_time',
        key: 'arrival_time',
        width: '16%',
      },
      {
        title: '',
        dataIndex: 'gate_no',
        key: 'gate_no',
        width: '10%',
      },
      {
        title: '',
        dataIndex: 'timer',
        key: 'timer',
        width: '14%',
      },
      {
        title: '',
        dataIndex: 'done',
        key: 'done',
      }
    ]
    return (
      <div className='warehouse-screen haulout-dash'>
        <div className='text-right f-spacebtw'>
          <div className='no-print'>
            {this.state.role?.toUpperCase() === 'WAREHOUSE TV' ?
              <div className='d-flex'>
                {
                  this.state.logintype && this.state.logintype === 'DashTV' ? null :
                    <div className='mr-1vw'>
                      <Button className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<ArrowLeftOutlined />} size='large' onClick={()=>window.location.href = '/landing-page'} />
                      <p className='btn-text'>Back</p>
                    </div>
                }
                <div>
                  <Button className='custom-icon-button warehouse-filter rotate-180' type='primary' shape='circle' icon={<LogoutOutlined />} size='large' onClick={this.handleRedirect} />
                  <p className='btn-text'>Signout</p>
                </div>
              </div>
              :
              <div>
                <Button className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<HomeOutlined />} size='large' onClick={this.handleRedirect} />
                <p className='btn-text'>Home</p>
              </div>
            }
          </div>
          <h1 className='dash-title mr-0 dash-loc'>{this.state.station}</h1>
        </div>
        <div className='clock-container'>
          <Text className='clock-text'>
            {this.state.clock}
          </Text>
        </div>  
        <h1 className='text-center'><span className='dash-title'>Haulout Dashboard</span></h1>
        <Row
          gutter={[
            {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
            },
            {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
            },
          ]}
        >
          <div className='w-100 f-spacebtw mb-1p5'>
            <div className='d-flex flex-6'>
              <div className='selected-filter mb-0'>Selected Filters:
                {this.renderFilterBlock()}
              </div>
            </div>
            <div span={2} className='row-1 pr-0 ml-auto bd-btns flex-1'>
              <Space direction='horizontal' className='no-print w-action-btn'>
                <Input.Search
                  placeholder='Search AWB'
                  onSearch={this.onSearch}
                  onChange={(e) => this.setSearchValue(e)}
                  allowClear
                  className={'warehouse-search' + (this.state.searchSelect? ' search-w100' : ' search-w0')}
                />
                <Button className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<PrinterOutlined />} size='large' onClick={()=>window.print()} />
                <Button disabled={this.state.wasSearched} className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<FilterOutlined />} size='large' onClick={this.showDrawer} />
              </Space>
            </div>
          </div>
          {/* <div className='clock-container'>
            <Text className='clock-text'>
              {this.state.clock}
            </Text>
          </div> */}
          <Col xs={24} sm={24} md={24} lg={24} className=''>
            <Spin spinning={this.state.loading}>
              <Table
                className='components-table-demo-nested warehouse-table haulout-table'
                columns={uld_columns}
                pagination={false}
                dataSource={this.renderData(this.state.hauloutList)}
                rowClassName={(record) => this.changeRowColor(record)}
                expandable={{
                  expandedRowRender: record => <Table className='warehouse-table nested-table haulout-nested-table' columns={awb_columns} dataSource={record?.awbs_in} pagination={false} />,
                  defaultExpandAllRows: true,
                  rowExpandable: (record) => record?.type !== 'BLK',
                  expandedRowKeys: this.state.expanded,
                  onExpandedRowsChange: (rows) => this.setState({ expanded: [] }, () => this.setState({ expanded: rows }))
                }}
                rowKey='id'
              />
            </Spin>
          </Col>
        </Row>

        <Drawer
          title=''
          placement='top'
          width={500}
          closable={false}
          open={this.state.visible}
          className='warehouse-drawer dr-haulout tv-res'
        >
          <Row>
            <Button className='close-btn ml-auto' onClick={this.onClose} size='large' type='link'>X</Button>
          </Row>
          <Row
            gutter={[
              {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 0,
              },
              {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 0,
              },
            ]}
            className='warehouse-screen'
          >
            <Col xs={24} sm={24} md={12} lg={5} xl={5} className='row-1'>
              <Form.Item label='Areas'>
                <Select
                  size='small'
                  placeholder='Select Areas'
                  dropdownClassName='warehouse-select'
                  value={this.state.workArea}
                  onChange={(e) => this.setState({
                    loading: true,
                    workArea: e,
                    visible: false,
                    page: 1,
                    buildup_list: [],
                    ac_type: null,
                    prod_codes: [],
                    prodCodeList: [],
                    orgdest: null,
                    orgDestList: [],
                    location: null,
                    locationList: [],
                    hrs_to_fpe: '',
                  }, () => this.applyFilters())}
                >
                  {
                    this.state.workAreaData && this.state.workAreaData.map((item) => (
                      <Option key={item.area_name} value={item.area_name}>{item.area_name}</Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={4} xl={4} className='row-1'>
              <Form.Item label='Hrs to Dep'>
                <InputNumber
                  size='small'
                  placeholder=''
                  className='w-100 m-0'
                  controls={false}
                  onChange={(e) => this.setState({ hrs_to_fpe: e || '' })}
                  value={this.state.hrs_to_fpe}
                >
                </InputNumber>
              </Form.Item>
            </Col>
            {/* <Col span={6} className='row-1'>
              <Form.Item label='A/C'>
                <Select
                  allowClear
                  showSearch
                  size='small'
                  placeholder='Select A/C'
                  dropdownClassName='warehouse-select'
                  value={this.state.ac_type}
                  onChange={(e) => this.setState({ ac_type: e && e !== undefined ? e : '' })}
                >
                  {this.state.acTypeList?.map((item) => (
                    <Option key={item?.value} value={item?.value}>{item?.value}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={24} md={12} lg={5} xl={5} className='row-1'>
              <Form.Item label='Product'>
                <Select
                  mode='multiple'
                  size='small'
                  value={this.state.prod_codes}
                  placeholder='Select product'
                  dropdownClassName='warehouse-select'
                  onChange={(e) => this.setState({ prod_codes: e?.includes('RESET') ? [] : e })}
                >
                  <Option className='blue' key={'RESET'} value={'RESET'}>{'RESET'}</Option>
                  {this.state.prodCodeList?.map((item) => (
                    <Option key={item} value={item}>{item}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={5} className='row-1'>
              <Form.Item label='Products'>
                <Select
                  mode='multiple'
                  size='small'
                  placeholder='Select Products'
                  dropdownClassName='warehouse-select'
                >
                  <Option value=''>All</Option>
                  <Option value=''></Option>
                  <Option value=''></Option>
                  <Option value=''></Option>
                </Select>
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={24} md={12} lg={5} xl={5} className='row-1'>
              <Form.Item label='Location'>
                <Select
                  allowClear
                  showSearch
                  size='small'
                  placeholder='Select Location'
                  dropdownClassName='warehouse-select'
                  value={this.state.location}
                  onChange={(e) => this.setState({ location: e && e !== undefined ? e : '' })}
                >
                  {this.state.locationList?.map((item) => (
                    <Option key={item?.value} value={item?.value}>{item?.value}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={5} xl={5} className='row-1'>
              <Form.Item label='ORG/DEST'>
                <Select
                  allowClear
                  showSearch
                  size='small'
                  placeholder='Select Org/Dest'
                  dropdownClassName='warehouse-select'
                  value={this.state.orgdest}
                  onChange={(e) => this.setState({ orgdest: e && e !== undefined ? e : '' })}
                >
                  {this.state.orgDestList?.map((item) => (
                    <Option key={item} value={item}>{item}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8} className='dash-checkbox'>
              <Form.Item label='Equipment'>
                <Checkbox checked={this.state.wbCheck} onChange={(e) => this.setState({ wbCheck: e.target.checked })}>WB</Checkbox>
                <Checkbox checked={this.state.nbCheck} onChange={(e) => this.setState({ nbCheck: e.target.checked })}>NB</Checkbox>
                <Checkbox checked={this.state.rfsCheck} onChange={(e) => this.setState({ rfsCheck: e.target.checked })}>RFS</Checkbox>
              </Form.Item>
            </Col>
            <Col className='ml-auto mt-auto mb-1vw'>
              <Space direction='horizontal'>
                <Button onClick={this.clearFilters} type='primary' className='custom-button warehouse-button mr-2vw'>Clear filters</Button>
                <Button onClick={() => this.applyFilters()} className='custom-button warehouse-button' type='primary'>Apply filters</Button>
              </Space>
            </Col>
          </Row>
        </Drawer>
      </div>
    )
  }
}

export default HauloutDashboard
